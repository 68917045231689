import React from 'react';
import {EditText, EditDropDown} from 'components/EditText/EditText';
import {Redirect} from 'react-router-dom';
import TextField, {Input} from '@material/react-text-field';

export default class NewTag extends React.Component {
  constructor (props) {
    super(props);
    this.state = {tag: props.tag};
  }

  handleAdd = async () => {
    const {tag} = this.state;
    const lastIdMinusOne = tag.options[tag.options.length - 1].id - 1;
    tag.options.push({id: lastIdMinusOne, label: ''});
    this.setState({tag: tag});
  };

  handleOptionEditChange = async (e, optionId) => {
    const {tag} = this.state;
    let editedOption = tag.options.find(o => o.id === optionId);
    editedOption.label = e.target.value;
    this.setState({tag: tag});
  };

  handleTitleEditChange = async (e) => {
    const {tag} = this.state;
    // tag.title = e.target.value;
    tag.title = e;
    this.setState({tag: tag});
  };

  handleNewOptionRemove = async (e, optionId) => {
    const {tag} = this.state;
    tag.options = tag.options.filter(o => o.id !== optionId);
    this.setState({tag: tag});
  };

  handleTypeChange = async (e) => {
    const {tag} = this.state;
    // tag.type = +e.target.value;
    tag.type = +e;
    this.setState({tag: tag});
  };

  handleCreateNewTag = async (e) => {
    const {tag} = this.state;
    this.props.handleCreateNewTagNewButton(e, tag);
  };

  render () {
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}}/>;
    let {tag} = this.state;
    return (
      <div key={tag.id} className='tag-plate'>
        <div className='tag-name-new'>
          {/* <input type={'text'} onChange={this.handleTitleEditChange} placeholder={'Tag Type Title'}/> */}
          <EditText onChange={this.handleTitleEditChange} label={'Title'} editing={1} value={tag.title} outerClassName='mr-1 mb-0 mt-3'/>
          <EditDropDown onChange={this.handleTypeChange} label={'Tag Type'} editing={1} className='mr-0 mb-0 mt-3' options={[{id: 1, name: 'Multiple Choice'}, {id: 2, name: 'Binary Choice'}]}/>
          {/* <select value={tag.type} onChange={this.handleTypeChange}> */}
          {/*  <option value="1">Multiple Choice</option> */}
          {/*  <option value="2">Binary Choice</option> */}
          {/* </select> */}
        </div>
        <div className={'tag-options'}>
          <TagOptions type={tag.type} options={tag.options} isAdd={this.state.isAdd} optionEdit={this.handleOptionEdit} optionEditChange={this.handleOptionEditChange} remove={this.handleNewOptionRemove} handleAdd={this.handleAdd}/>
        </div>
        <div className={'tag-create-buttons'}>
          <p className={'edittext-help'}>This tag will be displayed as a dropdown on every user's profile creation screen</p>
          <button onClick={this.handleCreateNewTag} className={'btn btn-primary tag-add-option-button'}>CREATE TAG
            TYPE
          </button>
          <button onClick={this.props.handleCancelNewButton} className={'btn btn-danger tag-delete-button'}>Cancel
          </button>
        </div>
      </div>
    );
  }
}

function TagOptions (props) {
  const options = props.options || [];
  let optionsList = null;
  if (props.type === 1) {
    optionsList = options.map((option, index) => {
      return (
        <div className={'tag-option'} key={option.id}>
          <OptionLine/>
          <Option option={option} editChange={props.optionEditChange} delete={props.delete}/>
          <div className={'option-control-buttons'}>
            {index > 1 ? <button className={'button-delete-option'} onClick={(e) => props.remove(e, option.id)}>remove</button> : ''}
          </div>
        </div>
      );
    });
  } else if (props.type === 2 && options[0]) {
    const option = options[0];
    optionsList = <div className={'tag-option-binary'}><Option option={option} editChange={props.optionEditChange} delete={props.delete}/></div>;
  }

  return (
    <div>
      {optionsList}
      {props.type === 1 ? <button onClick={props.handleAdd} className={'create-tag-add-option ml-4'}>+ Add Another</button> : null}
    </div>
  );
}

function Option (props) {
  return (
    <div className='tag-new-edit mb-1 mt-1'>
      <TextField label={'Label Text'} outlined style={{flex: 1}}>
        <Input type={'text'} value={props.option.label} onChange={e => props.editChange(e, props.option.id)}/>
      </TextField>
    </div>
    // <input type="text" value={props.option.label} onChange={e => props.editChange(e, props.option.id)}/>
  );
}

// Option line draws the left lines for the tag options, made up of 2 divs, top one has left and bottom border, bottom one has left only (except :last-of-type which has no border)
const OptionLine = props => (
  <div className='tag-line-outer'>
    <div className='tag-line-inner1'></div>
    <div className='tag-line-inner2'></div>
  </div>
);
