import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import {EditText} from 'components/EditText/EditText';
import {getUserFromToken} from 'lib/userprocs';
import {getData} from 'lib/data';
import SwitchBar from 'components/SwitchBar/SwitchBar';
import {post, dodelete} from 'lib/comms';
import {DeleteDialog, MessageDialog} from 'components/Dialog/Dialog';

export default class UserPage extends React.Component {
  constructor (props) {
    super(props);
    const myUser = getUserFromToken(getData('token', '', true));
    this.isMe = myUser.id === props.user.id;
    this.state = {user: props.user, editing: [false, false, false], showDeleteDialog: false, showMessage: null};
  }

  setEditing = async (index) => {
    let {editing, user} = this.state;
    if (editing[index]) { // save to db
      const resp = await post('cms/users', {user}, true);
      if (resp && resp.success) editing[index] = false;
      else console.log(resp);
      if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error'}}), 500);
    } else if (index === 1 && this.isMe) this.setState({showMessage: {message: 'You can\'t change your own user permissions!', title: 'Permissions'}});
    else editing[index] = true;
    this.setState({editing});
  }

  doDelete = async () => {
    const resp = await dodelete('cms/users', {id: this.state.user.id}, true);
    await this.setState({showDeleteDialog: false});
    if (resp && resp.success && this.props.onBack) this.props.onBack();
    else if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Delete user'}}), 500);
  }

  render () {
    let {user, editing} = this.state;
    return (
      <div className="maincontainer">
        <BackRow onBack={this.props.onBack} buttonTitle='DELETE USER' onDelete={() => this.setState({showDeleteDialog: true})}/>
        <div className="row">
          <div className="col">
            <Card title="Personal information" editing={editing[0] ? '1' : undefined} onToggle={() => this.setEditing(0)}>
              <p>Status: Registered</p>
              <div className="two-cols" style={{marginBottom: '-10px'}}>
                <div style={{flex: 1, marginRight: '6px', maxWidth: '200px'}}>
                  <EditText label="First Name" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({user: {...user, first_name: text}})} value={user.first_name}/>
                </div>
                <div style={{flex: 1, marginLeft: '6px'}}>
                  <EditText label="Last Name" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({user: {...user, last_name: text}})} value={user.last_name}/>
                </div>
              </div>
              <EditText outerClassName="mb-3 mt-2" label="Email Address" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({user: {...user, email_address: text}})} value={user.email_address}/>
              <EditText label="Job Title" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({user: {...user, job_title: text}})} value={user.job_title}/>
            </Card>
          </div>
          <div className="col">
            <Card title="Permissions" editing={editing[1] ? '1' : undefined} onToggle={() => this.setEditing(1)}>
              <SwitchBar editing={editing[1] ? '1' : undefined} options={['Basic', 'Advanced']} values={[0, 1]} value={user.user_level} onChange={value => this.setState({user: {...user, user_level: value}})}/>
            </Card>
            <Card title="Authentication Method" editing={editing[2] ? '1' : undefined} onToggle={() => this.setEditing(2)}>
              <SwitchBar editing={editing[2] ? '1' : undefined} options={['Magic Link', 'Password']} values={['magic_link', 'password']} value={user.auth_method} onChange={value => this.setState({user: {...user, auth_method: value}})}/>
              {editing[2] && user.auth_method === 'password' ? <EditText label="Password" editing={editing[2]} onChange={text => this.setState({user: {...user, password: text}})} value={user.password} type="password"/> : null}
            </Card>
          </div>
        </div>

        {this.state.showDeleteDialog ? <DeleteDialog item='User' onSuccess={this.doDelete} onClose={() => this.setState({showDeleteDialog: false})}/> : null}
        {this.state.showMessage ? <MessageDialog message={this.state.showMessage.message} title={this.state.showMessage.title} onClose={() => this.setState({showMessage: null})}/> : null}

      </div>
    );
  }
}
