import React from 'react';
import {get} from 'lib/comms';
import {createDashboardWidgetSettings} from '../../../../../lib/dashboard';
import SmallLoader from '../../../../../components/SmallLoader/SmallLoader';

const colors = [
  {background: '#D9A441', text: '#2D2D2D'},
  {background: '#A8A8A8', text: '#2D2D2D'},
  {background: '#965A37', text: '#FFFFFF'},
  {background: '#0F2B41', text: '#FFFFFF'}
];

export default class extends React.Component {
  state = {data: [], status: 'LOADING'};

  componentDidMount = async () => this.reload();

  reload = async () => {
    const {settings} = this.props;

    this.setState({
      status: 'LOADING'
    });

    const {data} = await get('cms/dashboard_data/points', createDashboardWidgetSettings(settings), true);

    if (Array.isArray(data)) {
      this.makeXLSPage(data);
      this.setState({
        data,
        status: 'READY'
      });
    }
  }

  makeXLSPage = (data) => {
    let out = [['First name', 'Surname', 'Email', 'Points']];
    data.forEach(record => out.push([record.first_name, record.last_name, record.email_address, record.points]));
    if (this.props.onXLSData) this.props.onXLSData(out);
  }

  render () {
    const {data, status} = this.state;
    const {width, settings} = this.props;

    if (status === 'LOADING') {
      return <div style={localStyle.outer}><SmallLoader /></div>;
    }

    const {count = 5} = settings;
    const showData = data.slice(0, count);
    return (
      <div style={localStyle.outer}>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start'}}>
          {showData.map((item, index) => (
            <div style={{...localStyle.medalOuter, width: `${100 / width}%`}} key={index}>
              <div style={{...localStyle.medal, backgroundColor: (colors[index] || colors[3]).background, color: (colors[index] || colors[3]).text}}>
                {item.points}
              </div>
              <div style={localStyle.textOuter}>
                <p className="mb-0" style={localStyle.nameText}>{`${item.first_name || ''} ${item.last_name || ''}`}</p>
                <p className="mb-0 grey" style={localStyle.emailText}>{item.email_address}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const localStyle = {
  outer: {padding: 16, overflowY: 'scroll'},
  medalOuter: {display: 'flex', flexDirection: 'row', height: '66px'},
  medal: {minWidth: '50px', width: '50px', height: '50px', borderRadius: '4px', marginRight: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', fontWeight: 600},
  textOuter: {display: 'flex', flexDirection: 'column'},
  nameText: {fontSize: '17px', letterSpacing: '-0.2px', textOverflow: 'ellipsis', height: '22px', overflow: 'hidden', wordWrap: 'break-word'},
  emailText: {fontSize: '15px', letterSpacing: '-0.15px'}
};
