import React from 'react';
import './Card.css';

export default (props) => {
  return (
    <div className="card" style={props.style || {}}>
      <div className={props.editing ? 'card-title-outer editing' : 'card-title-outer'} style={{paddingTop: props.onToggle ? '10px' : '12px', paddingBottom: props.onToggle ? '10px' : '12px'}}>
        <div className="card-title">{props.title}</div>
        {
          props.onToggle && (
            props.saving
              ? (
                <div className="card-edit text-button">
                  {'Saving...'}
                </div>
              )
              : (
                <button className="card-edit text-button" onClick={props.onToggle}>
                  {props.editing ? 'Save' : 'Edit'}
                </button>
              )

          )
        }
        {props.options && props.onOption ? <Select options={props.options} onChange={e => props.onOption(e.target.value)}/> : null}
      </div>
      {!props.nodivider ? <hr className="card-divider"/> : null}
      <div className="card-body-outer">
        {props.children}
      </div>
    </div>
  );
};

const Select = ({options, onChange}) => (
  <select onChange={onChange}>
    {options.map(item => <option key={item}>{item}</option>)}
  </select>
);
