import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import {EditText} from 'components/EditText/EditText';
import {MessageDialog} from 'components/Dialog/Dialog';
import {post} from 'lib/comms';
import moment from 'moment';
import {setData, getData} from '../../../../lib/data';

export default class GroupPage extends React.Component {
  constructor (props) {
    super(props);
    let {organisation = {}} = props;
    const isNew = organisation.id === 'new';
    const supportActive = organisation.support_window_expires_at && new Date(organisation.support_window_expires_at) > new Date();
    this.state = {organisation, isNew, editing: [isNew], showMessage: null, supportActive};
  }

  setEditing = async (index) => {
    let {editing, organisation} = this.state;
    if (editing[index]) { // save to db
      if (!organisation.company_name) return;
      const resp = await post('cms/organisations', {organisation}, true);
      if (resp && resp.success) {
        editing[index] = false;
        if (resp.id) this.setState({organisation: {...organisation, id: resp.id}, isNew: false});
      } else console.log(resp);
      if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error'}}), 500);
    } else editing[index] = true;
    this.setState({editing});
  }

  requestSupportToken = async () => {
    const tokenRequest = await post(`cms/support/join/${this.state.organisation.id}`, {}, true);

    if (tokenRequest && tokenRequest.token) {
      // save existing token in another cookie as a backup so we can return from support mode
      setData('token_admin', getData('token', null), true);
      setData('token', tokenRequest.token, true);
      window.location = '/';
    } else {
      this.setState({
        showMessage: {
          message: `Couldn't active remote support for ${this.state.organisation.company_name}.`
        }
      });
    }
  }

  render () {
    let {organisation, editing, isNew, supportActive} = this.state;

    return (
      <div className="maincontainer">
        <BackRow onBack={this.props.onBack}/>

        <Card title="Organisation information" editing={editing[0] ? '1' : undefined} onToggle={() => this.setEditing(0)}>
          {organisation.member_count ? <p className="mt-2 mb-2" style={{color: '#717171'}}>Members: {organisation.member_count}</p> : null}
          <EditText label="Organisation Name" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({organisation: {...organisation, company_name: text}})} value={organisation.company_name}/>
          <EditText className="mt-3" label="Points Cap" editing={editing[0] ? '1' : undefined} onChange={text => !isNaN(text) && this.setState({organisation: {...organisation, points_cap: Math.round(text)}})} value={organisation.points_cap}/>
          {isNew ? <EditText className="mt-3" label="Admin login (email)" editing={editing[0] ? '1' : undefined} helpText="Add an email address so that they can log in" onChange={text => this.setState({organisation: {...organisation, email: text}})} value={organisation.email}/> : null}
          {isNew ? <EditText className="mt-1" label="Admin password" editing={editing[0] ? '1' : undefined} helpText="Leave blank for magic link login" type="password" onChange={text => this.setState({organisation: {...organisation, password: text}})} value={organisation.password}/> : null}
        </Card>

        {
          !isNew && (
            <Card title="Support">
              <div className="mt-2">
                {
                  supportActive
                    ? (
                      <div>
                        <button className="btn btn-primary" onClick={this.requestSupportToken}>REMOTE SUPPORT</button>
                        <br />
                        <div className="mt-2">Remote support expires <b>{moment(organisation.support_window_expires_at).fromNow()}</b> ({moment(organisation.support_window_expires_at).format('dddd, MMMM Do YYYY, h:mm:ssa')})</div>
                      </div>
                    )
                    : (
                      <p>Organisation has not activated remote support.</p>
                    )
                }
              </div>
            </Card>
          )
        }

        {this.state.showMessage ? <MessageDialog message={this.state.showMessage.message} title={this.state.showMessage.title} onClose={() => this.setState({showMessage: null})}/> : null}

      </div>
    );
  }
}
