import React from 'react';

export const Dot = (props) => {
  const {radius = 6, color = '#FF0'} = props;
  return (
    <svg width={radius * 2 + 4} height={radius * 2 + 4} className={props.className}>
      <circle cx={radius} cy={radius} r={radius} stroke="black" strokeWidth="0" fill={color} />
    </svg>
  );
};

export const CrossBox = (props) => {
  const {size = 20, color = '#CF376B'} = props;
  const [edge, farEdge, stroke] = [Math.round(size / 5), size - Math.round(size / 5), Math.round(size / 6)];
  return (
    <svg width={size + 4} height={size + 4} className={props.className}>
      <rect width={size} height={size} stroke="black" strokeWidth="0" fill={color} />
      <path d={`M${edge},${edge} L${farEdge},${farEdge}`} stroke="white" strokeWidth={stroke}/>
      <path d={`M${farEdge},${edge} L${edge},${farEdge}`} stroke="white" strokeWidth={stroke}/>
    </svg>
  );
};

export const TickBox = (props) => {
  const {size = 20, color = '#4AB792'} = props;
  const [edge, farEdge, stroke] = [Math.round(size / 5), size - Math.round(size / 5), Math.round(size / 6)];
  return (
    <svg width={size + 4} height={size + 4} className={props.className}>
      <rect width={size} height={size} stroke="black" strokeWidth="0" fill={color} />
      <path d={`M${edge},${size / 2} L${edge * 2},${farEdge - 2} L${farEdge},${edge}`} stroke="white" strokeWidth={stroke} fill={color}/>
    </svg>
  );
};
