import React, {useRef, useEffect} from 'react';
import './EditText.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/free-regular-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import TextField, {Input} from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const EditText = (props) => {
  const className = `${props.className || ''} edittext-outer ${props.editing ? 'editing' : 'notediting'}`;
  let {helpText, maxLength, name, value, onChange, editing, type = 'text'} = props;
  if (maxLength) {
    const lengthLeft = Math.max(maxLength - (value || '').length, 0);
    helpText = `${lengthLeft} Character${lengthLeft === 1 ? '' : 's'} left`;
  }
  return (
    <div className={props.outerClassName || 'mt-3 mb-3'}>
      <TextField className={className} label={props.label} outlined style={{marginLeft: props.marginLeft || '0'}}>
        <Input maxLength={maxLength} className="poppins" type={type} name={name} value={value || ''} onChange={onChange && editing ? e => onChange(e.currentTarget.value) : null} readOnly={editing ? null : 'On'} disabled={editing ? null : 'disabled'}/>
      </TextField>
      {helpText ? <p className="edittext-help">{helpText}</p> : null}
    </div>
  );
};

export const EditTextArea = (props) => {
  let {helpText, maxLength, value, onChange, editing, label} = props;
  if (maxLength) {
    const lengthLeft = Math.max(maxLength - (value || '').length, 0);
    helpText = `${lengthLeft} Character${lengthLeft === 1 ? '' : 's'} left`;
  }
  const className = `mdc-text-field edittext-outer mdc-text-field--outlined ${editing ? 'editing' : 'notediting'} ${props.className || ''} `;
  return (
    <div className={`mt-3 mb-3 ${props.outerClassName || ''}`}>
      <div className={className} style={{height: '180px'}}>
        <textarea maxLength={maxLength} className="mdc-text-field__input poppins" rows="8" type="text" style={{height: '180px'}} value={value} onChange={onChange && editing ? e => onChange(e.currentTarget.value) : null} readOnly={editing ? null : 'On'} disabled={editing ? null : 'disabled'}/>
        <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
          <div className="mdc-notched-outline__leading"></div>
          <div className="mdc-notched-outline__notch" style={{width: 20 + label.length * 6 + 'px'}}>
            <label className="mdc-floating-label mdc-floating-label--float-above">{label}</label>
          </div>
          <div className="mdc-notched-outline__trailing"></div>
        </div>
      </div>
      {helpText ? <p className="edittext-help">{helpText}</p> : null}
    </div>
  );
};

export const EditRichTextArea = (props) => {
  let {helpText, maxLength, value, onChange, editing, label} = props;

  const quill = useRef(null);

  useEffect(() => {
    if (!editing && quill.current) {
      quill.current.blur();
    }
  }, [editing]);

  if (maxLength) {
    const lengthLeft = Math.max(maxLength - (value || '').length, 0);
    helpText = `${lengthLeft} Character${lengthLeft === 1 ? '' : 's'} left`;
  }
  const className = `mdc-text-field edittext-outer mdc-text-field--outlined ${editing ? 'editing' : 'notediting'} ${props.className || ''} `;

  return (
    <div className={`mt-3 mb-3 ${props.outerClassName || ''}`}>
      <div className={className} style={{height: '224px', pointerEvents: editing ? 'all' : 'none'}}>
        <ReactQuill ref={quill} className="quill-rich" style={{opacity: editing ? 1 : 0.8}} value={value} onChange={onChange && editing ? content => onChange(content) : null} readOnly={editing ? null : 'On'} disabled={editing ? null : 'disabled'} />
        {/* <textarea maxLength={maxLength} className="mdc-text-field__input poppins" rows="8" type="text" /> */}
        <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
          <div className="mdc-notched-outline__leading"></div>
          <div className="mdc-notched-outline__notch" style={{width: 20 + label.length * 6 + 'px'}}>
            <label className="mdc-floating-label mdc-floating-label--float-above">{label}</label>
          </div>
          <div className="mdc-notched-outline__trailing"></div>
        </div>
      </div>
      {helpText ? <p className="edittext-help">{helpText}</p> : null}
    </div>
  );
};

export const EditDropDown = (props) => {
  let className = `mdc-text-field edittext-outer mt-3 mb-3 mdc-text-field--outlined ${props.editing ? 'editing' : 'notediting'} ${props.className || ''}`;
  if (props.className && props.className.indexOf('mb-') >= 0) className = className.replace('mb-3', '');
  if (props.className && props.className.indexOf('mt-') >= 0) className = className.replace('mt-3', '');
  return (
    <div className={className} style={props.style || {}}>
      <select className="mdc-text-field__input poppins" value={props.value} onChange={props.onChange && props.editing ? e => props.onChange(e.currentTarget.value) : null} disabled={props.editing ? null : 'On'}>
        {props.options.map(option => <option key={option.id || option} value={option.id || option}>{option.name || option}</option>) }
      </select>
      <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
        <div className="mdc-notched-outline__leading"></div>
        <div className="mdc-notched-outline__notch" style={{width: props.label.length * 7 + 'px'}}>
          <label className="mdc-floating-label mdc-floating-label--float-above">{props.label}</label>
        </div>
        <div className="mdc-notched-outline__trailing"></div>
      </div>
    </div>

  );
};

export const EditDate = (props) => {
  const className = `${props.className || ''} mdc-text-field edittext-outer mt-3 mb-3 mdc-text-field--outlined ${props.editing ? 'editing' : 'notediting'}`;
  return (
    <div className={className}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '150px'}}>
        <FontAwesomeIcon icon={faCalendar} className="ml-3 mb-1" color='#999' size='lg'/>
        <DatePicker calendarClassName="rasta-stripes" selected={moment.utc(props.value).toDate()} dateFormat="dd/MM/yyyy" className="mdc-text-field__input poppins" onChange={props.onChange && props.editing ? date => props.onChange(moment.utc(date).format('YYYY-MM-DD')) : null} disabled={props.editing ? null : 'On'}/>
        {/* <input className="mdc-text-field__input poppins" style={{padding: '10px', width: '180px'}} type="date" value={props.value} onChange={props.onChange && props.editing ? e => props.onChange(e.currentTarget.value) : null} disabled={props.editing ? null : 'On'}/> */}
      </div>
      <div className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
        <div className="mdc-notched-outline__leading"></div>
        <div className="mdc-notched-outline__notch" style={{width: props.label.length * 7 + 'px'}}>
          <label className="mdc-floating-label mdc-floating-label--float-above">{props.label}</label>
        </div>
        <div className="mdc-notched-outline__trailing"></div>
      </div>
    </div>
  );
};
