export const createDashboardWidgetSettings = (settings) => {
  const requestSettings = {};

  if (settings.tags && settings.tags.length > 0) {
    requestSettings.tags = JSON.stringify(settings.tags);
  }

  if (settings.department && settings.department.length > 0 && settings.department[0] !== 0) {
    requestSettings.department = JSON.stringify(settings.department);
  }

  if (settings.time) {
    requestSettings.time = (settings.time);
  }

  return requestSettings;
};
