import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {get} from 'lib/comms';
import Table from 'components/Table/Table';
import MicroLearningPage from './MicroLearningPage';
import moment from 'moment';
import {Redirect} from 'react-router-dom';

const columns = [
  {name: 'Micro-Learning Material', selector: 'name', sortable: true, grow: 3, wrap: true},
  {name: 'Date Posted', selector: 'posted_date', sortable: true, format: row => row.posted_date ? moment(row.posted_date).format('DD MMM YYYY') : ''},
  {name: 'Completed by', selector: 'user_count', center: true, sortable: true},
  {name: 'Average Score', selector: 'score', center: true, sortable: true, format: row => row.score ? `${(100 * row.score / (row.user_count * (row.out_of || 1))).toFixed(0)}%` : ''},
  {name: 'Status', selector: 'active', sortable: true, center: true, format: row => row.posted_date ? row.active ? 'Active' : 'Archived' : 'Not posted'},
  {name: '', selector: 'id', sortable: false, grow: 0, right: true, cell: () => <FontAwesomeIcon color='#999' icon={faChevronRight}/>}
];

export default class MicroLearningsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {data: [], search: '', editing: null};
  }

  doneEditing = () => {
    this.setState({editing: null});
    this.reload();
  }

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get('cms/learnings', null, true);
    if (resp && resp.status === 401) this.setState({logout: true});
    if (resp && resp.learnings) this.setState({data: resp.learnings});
    else console.log(resp);
  }

  render () {
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}}/>;
    let {data, search, editing} = this.state;
    if (editing) return <MicroLearningPage learning={editing} onBack={this.doneEditing}/>;
    if (search) data = data.filter(item => `${item.name} ${item.category}`.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    return (
      <div className="maincontainer">
        <h4>Micro-Learning</h4>
        <SearchRow value={search} searchTitle='Search by name of Micro-Learning material' onSearch={(search) => this.setState({search})} buttonTitle='ADD NEW MATERIAL' onBtnClick={(item) => this.setState({editing: {id: 'new'}})} />
        <Table columns={columns} data={data} onRowClicked={(item) => this.setState({editing: item})} tableID='microlearning' defaultSortField={columns[1].selector}/>
      </div>
    );
  }
}
