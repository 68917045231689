import React, {useState, useCallback} from 'react';
import './Dialog.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import TextField, {Input} from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import {EditDropDown} from 'components/EditText/EditText';
import CSVDrop from './CSVDrop';
import LineText from './LineText';
import linkImage from 'assets/magic_link.png';

export const DeleteDialog = (props) => {
  const {item = 'User'} = props;
  const title = `Delete ${item}`;
  const confirm = `Are you sure you want to delete this ${item.toLowerCase()}? This action canot be undone.`;
  const btnYes = `YES, DELETE THIS ${item.toUpperCase()}`;
  const btnNo = `NO, DON'T DELETE THIS ${item.toUpperCase()}`;
  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}>
      <div className="dialog-inner">
        <p className="dialog-title">{title}</p>
        <p className="dialog-text">{confirm}</p>
        <button className="btn btn-primary dialog-button" onClick={props.onSuccess}>{btnYes}</button>
        <button className="btn btn-outline-primary dialog-button" onClick={props.onClose}>{btnNo}</button>
        <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button>
      </div>
    </div>
  );
};

export const MessageDialog = (props) => {
  const {title, message} = props;
  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}>
      <div className="dialog-inner">
        <p className="dialog-title">{title}</p>
        <p className="dialog-text">{message}</p>
        <button className="btn btn-primary dialog-button" onClick={props.onClose}>OK</button>
        <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button>
      </div>
    </div>
  );
};

export const PinnedPostLimitDialog = (props) => {
  const {title, message, pinnedPosts, onClose, onSelect} = props;

  const posts = pinnedPosts.map(post => ({
    id: post.id,
    name: `${post.notice.slice(0, 120)}${post.notice.length > 120 ? '...' : ''}`
  }));

  const [selectedPost, setSelectedPost] = useState(pinnedPosts[0]);
  const [status, setStatus] = useState('READY'); // READY, LOADING

  const onDropDownChange = useCallback(post => {
    setSelectedPost(post);
  }, []);

  const onSelectPost = useCallback(() => {
    setStatus('LOADING');
    onSelect(selectedPost);
  }, [selectedPost]);

  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && onClose()}>
      <div className="dialog-inner" style={status === 'LOADING' ? {
        pointerEvents: 'none',
        opacity: 0.8
      } : {}}>
        <p className="dialog-title">{title}</p>
        <p className="dialog-text">{message}</p>
        <EditDropDown label="Pinned Notices" options={posts} editing={1} onChange={onDropDownChange} value={selectedPost.id}/>
        <button className="btn btn-primary dialog-button" onClick={onSelectPost}>Unpin and save</button>
        <button className="btn btn-outline-primary dialog-button" onClick={onClose}>Cancel</button>
        <button className="dialog-close text-button" onClick={onClose}><FontAwesomeIcon icon={faTimes}/></button>
      </div>
    </div>
  );
};

export const InputDialog = (props) => {
  const [value, setValue] = useState('');
  const {hint, buttonText} = props;
  return (
    <Outer {...props}>
      <TextField className="mt-2 mb-2" label={hint} outlined style={{width: '100%'}}>
        <Input className="poppins" value={value} onChange={e => setValue(e.target.value)}/>
      </TextField>
      <button className="btn btn-primary dialog-button" onClick={() => props.onSuccess(value)}>{buttonText || 'OK'}</button>
      <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button>
    </Outer>
  );
};

const Outer = (props) => {
  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}>
      <div className="dialog-inner">
        <p className="dialog-title">{props.title}</p>
        {props.children}
      </div>
    </div>
  );
};

export const MagicLinkDialog = (props) => {
  const {email} = props;
  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}>
      <div className="dialog-inner" style={{width: '600px'}}>
        <img src={linkImage} alt="magic link" className=""/>
        <h4>Magic Link Sent!</h4>
        <p className="dialog-link-text mt-2">We just sent a link to you at</p>
        <p className="dialog-link-text"><strong>{email}</strong></p>
        <p className="dialog-link-text mb-3">It contains a link that'll sign you in</p>
        {/* <button className="btn btn-primary dialog-button" onClick={props.onClose} style={{maxWidth: '410px'}}>OK</button> */}
        {/* <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button> */}
      </div>
    </div>
  );
};

export class AddUserDialog extends React.Component {
  state = {email: '', validEmail: false};

  updateEmail = (e) => {
    const email = e.currentTarget.value;
    const validEmail = validateEmail(email);
    this.setState({email, validEmail});
  }

  render () {
    return (
      <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && this.props.onClose()}>
        <div className="dialog-inner">
          <p className="dialog-title">Add New User</p>
          <TextField className="mt-2" label="Email address" outlined style={{width: '100%'}}>
            <Input className="poppins" value={this.state.email} onChange={this.updateEmail}/>
          </TextField>
          <LineText text="Or"/>
          <CSVDrop onSuccess={(emails) => this.props.onSuccess(emails)}/>
          <button className="btn btn-primary dialog-button mt-4" disabled={this.state.validEmail ? null : 'on'} onClick={() => this.props.onSuccess(this.state.email)}>ADD</button>
          <button className="dialog-close text-button" onClick={this.props.onClose}><FontAwesomeIcon icon={faTimes}/></button>
        </div>
      </div>
    );
  }
}

export const ChangeDepartmentDialog = (props) => {
  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}>
      <div className="dialog-inner">
        <p className="dialog-title">Change department</p>
        <p className="dialog-text">Select a new department for: <br /><b>{props.userName}</b></p>
        <EditDropDown label="Departments" options={props.departments} editing={1} onChange={props.changeDepartment} value={props.currentDepartmentId}/>
        <button className="btn btn-primary dialog-button" onClick={props.onSuccess}>Confirm Change</button>
        <button className="btn btn-outline-primary dialog-button" onClick={props.onClose}>Cancel</button>
        <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button>
      </div>
    </div>
  );
};

export const DeleteDepartmentDialog = (props) => {
  return (
    <div className="dialog-overlay-outer" onClick={(e) => e.target.className === 'dialog-overlay-outer' && props.onClose()}>
      <div className="dialog-inner">
        <p className="dialog-title">Delete Department</p>
        { props.membersCount
          ? <>
            <p className="dialog-text">Select a new department for <br /> <b>{props.membersCount} {props.membersCount > 1 ? 'users' : 'user'}</b> to be moved to before deletion</p>
            <EditDropDown label="Departments" options={props.departments} editing={1} onChange={props.changeDepartment} value={props.currentDepartmentId}/>

          </>
          : null }
        { (props.departments.length === 0 && props.membersCount > 0)
          ? <p>There are no other departments to transfer users to.</p>
          : <button className="btn btn-primary dialog-button" onClick={props.onSuccess}>CONFIRM DELETION</button>
        }
        <button className="btn btn-outline-primary dialog-button" onClick={props.onClose}>CANCEL</button>
        <button className="dialog-close text-button" onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></button>
      </div>
    </div>
  );
};

function validateEmail (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // eslint-disable-line 
  return re.test(String(email).toLowerCase());
}
