import React from 'react';
import './TabRow.css';

export default ({tabs, activeTab, onChange}) => (
  <div className="tabrow-outer">
    <div className="tabrow-inner">
      {tabs.map(tab => <button key={tab} onClick={() => onChange(tab)} className={`tabrow-tab ${activeTab === tab ? 'active' : ''}`}>{tab}</button>)}
    </div>
    <hr className="tab-line"/>

  </div>
);
