import React, {useState, useEffect} from 'react';
import moment from 'moment';
import Select from 'react-select';
import {get} from 'lib/comms';

import {widgetList} from './index';

import flatten from 'lodash/flatten';

const remoteOptions = [{id: 'both', name: 'Remote (Yes & No)'}, {id: 'yes', name: 'Remote (Yes)'}, {id: 'no', name: 'Remote (No)'}];
const timeOptions = [{id: 'thismonth', name: 'Current month'}, {id: '7days', name: 'Last 7 days'}, {id: 'prevmonth', name: `Last month (${moment().subtract(1, 'month').format('MMM')})`}, {id: '6months', name: 'Last 6 months'}];

export default ({settings = {}, width, onUpdate, onCancel, onSave, departments, locations, widgetType}) => {
  const [currentSettings, setCurrentSettings] = useState({
    ...settings,
    tags: settings.tags && settings.tags.length > 0 ? settings.tags : []
  } || {});

  const [userTags, setUserTags] = useState([]);

  useEffect(() => {
    (async () => {
      let resp = await get('content/tag', null, true);
      if (resp.tags) {
        setUserTags(resp.tags);
      }
    })();
  }, []);

  const updateSetting = (name, val) => {
    if (name === 'count') val = Math.max(Math.min(parseInt(val, 10) || 5, 12), 1);
    if (val === null) val = [{value: 0}];
    if (Array.isArray(val)) {
      val = val.map(item => item.value);
      if (val.length <= 0 || val[val.length - 1] === 0) val = [0];
      if (val.length >= 2 && val[0] === 0) val = val.slice(1);
    } else if (val.value) val = val.value;
    currentSettings[name] = val;
    setCurrentSettings({...currentSettings});
  };

  const updateUserTagSettings = (newSelectedTags, userTagId, allSelectedTags) => {
    // A - get all the selected tags but the ones for that setting
    // B - convert the ones from the component into the selected tag format
    // C - append and save the newly formatted tags to the array of filtered tags got in step A

    // A
    const tmpTags = [...allSelectedTags.filter(selectedTag => selectedTag.tagId !== userTagId)];

    // B
    let tmpNewTags = newSelectedTags ? newSelectedTags.map(newSelectedTag => ({tagId: userTagId, optionId: newSelectedTag.value})) : [];

    // C
    setCurrentSettings({
      ...currentSettings,
      tags: [...tmpTags, ...tmpNewTags]
    });
  };

  const widgetTypeData = widgetList.find(item => item.type === widgetType);
  const showCount = widgetType === 'top' && false;
  const showRemote = widgetTypeData.remote !== false && false;
  const showDept = widgetTypeData.department !== false;
  const showLocation = widgetTypeData.location !== false && false;
  const showTags = !(widgetTypeData.tags === false);
  const showTime = true;

  return (
    <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080', borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 12}}>
      <div style={{backgroundColor: '#FFF', borderRadius: 4, width: width > 1 ? 320 : '100%', display: 'flex', flexDirection: 'column', padding: width > 1 ? 12 : 8, overflowY: 'scroll'}}>
        <div style={localStyle.settingsTitle}>Settings</div>
        {showCount ? <EditRow name="count" text="Total top users" value={currentSettings.count} onChange={updateSetting} /> : null}
        {showRemote ? <DropdownRow name="remote" value={currentSettings.remote} onChange={updateSetting} options={remoteOptions} /> : null}
        {showDept ? (
          <div style={{marginTop: 5, marginBottom: 5}}>
            <div style={{fontSize: 12}}>Departments</div>
            <MultiRow name="department" value={currentSettings.department} onChange={updateSetting} options={departments} />
          </div>)
          : null
        }
        {showLocation ? <MultiRow name="location" value={currentSettings.location} onChange={updateSetting} options={locations} /> : null}
        {
          showTime ? (
            <div style={{marginTop: 5, marginBottom: 5}}>
              <div style={{fontSize: 12}}>Time</div>
              <DropdownRow name="time" value={currentSettings.time} onChange={updateSetting} options={timeOptions} />
            </div>)
            : null
        }
        {
          showTags && userTags.map(userTag => {
            return (
              <UserTagRow
                userTag={userTag}
                selectedTags={currentSettings.tags}
                onChange={updateUserTagSettings}
              />
            );
          })
        }
        <div style={localStyle.settingsFooter}>
          <button className="text-button" onClick={onCancel}>Cancel</button>
          <button className="blue-button" onClick={() => {
            onSave(currentSettings);
          }}>Save</button>
        </div>
      </div>
    </div>
  );
};

const localStyle = {
  settingsOuter: {flex: 1, display: 'flex', flexDirection: 'column'},
  settingsTitle: {fontSize: 18, color: '#0D314A', fontWeight: '600', marginBottom: 4},
  settingsInner: {flex: 1, backgroundColor: '#FF0'},
  select: {
    valueContainer: styles => ({...styles, fontSize: 12, height: 32, maxHeight: 32, overflowX: 'scroll', display: 'flex', flexDirection: 'row', flexWrap: 'none'}),
    multiValueRemove: (base, state) => state.data.value === 0 ? {...base, display: 'none'} : base,
    multiValueLabel: (base, state) => state.data.value === 0 ? {...base, fontSize: 12, backgroundColor: '#FFF'} : base
  },
  row: {display: 'flex', flexDirection: 'row'},
  settingsFooter: {display: 'flex', alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, marginBottom: 0}
};

// export const DropdownRow2 = ({name = 'remote', onChange, value, options}) => {
//   const optionArray = Array.isArray(options) ? options : Object.keys(options).map(id => ({id, name: options[id]}));

//   return (
//     <select onChange={(event) => onChange(name, event.target.value)} value={value} className="mt-2">
//       {optionArray.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
//     </select>
//   );
// };

export const DropdownRow = ({name = 'remote', onChange, value, options}) => {
  const optionArray = Array.isArray(options) ? options : Object.keys(options).map(id => ({id, name: options[id]}));
  const selectOptions = optionArray.map(item => ({value: item.id, label: item.name}));
  value = selectOptions.find(item => item.value === value);
  if (value === undefined) value = selectOptions[0];
  return <Select options={selectOptions} value={value} styles={localStyle.select} className='mt-2' onChange={(value) => onChange(name, value)} />;
};

export const MultiRow = ({name, onChange, value, options}) => {
  const optionArray = Array.isArray(options) ? options : Object.keys(options).map(id => ({id, name: options[id]}));
  const selectOptions = optionArray.map(item => ({value: parseInt(item.id), label: item.name}));

  if (!Array.isArray(value)) value = [0];
  if (value.length <= 0) value = [0];
  if (value.length >= 2 && value[0] === 0) value = value.slice(1);
  if (value.length >= 2 && value[value.length - 1] === 0) value = [0];
  const values = value.map(id => selectOptions.find(val => val.value === parseInt(id))); // .filter(item => item);

  return <Select options={selectOptions} styles={localStyle.select} className='mt-2' isMulti value={values} onChange={(value) => onChange(name, value)} />;
};

const formatSelectedOptions = (userTag, selectedTags) => {
  const output = flatten(selectedTags
    .filter(selectedTag => selectedTag.tagId === userTag.id) // OK
    .map(selectedTag => {
      return userTag.options
        .filter(singleOption => singleOption.id === selectedTag.optionId)
        .map(item => ({value: item.id, label: item.label}));
    }));

  return output;
};

export const UserTagRow = ({onChange, userTag = {}, selectedTags = []}) => {
  const selectOptions = userTag.options.map(item => ({value: item.id, label: item.label}));

  const selectedOptions = formatSelectedOptions(userTag, selectedTags);

  return (
    <div style={{marginTop: 5, marginBottom: 5}}>
      <div style={{fontSize: 12}}>{userTag.title}</div>
      <Select
        options={selectOptions}
        styles={localStyle.select}
        className='mt-2'
        value={selectedOptions}
        isMulti
        onChange={(newSelectedTags) => {
          onChange(newSelectedTags, userTag.id, selectedTags);
        }}
      />
    </div>
  );
};

export const EditRow = ({name = 'count', text, onChange, value = '5'}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>{text}</div>
      <input style={{width: 40, textAlign: 'center', border: '1px solid #999', borderRadius: 4}} onChange={e => onChange(name, e.target.value)} value={value} />
    </div>
  );
};

export const getSettingsText = (settings, departments, locations, options) => {
  const {time = 'thismonth', department = 0} = settings;
  // const {name: remoteName} = remoteOptions.find(item => item.id === remote) || {};
  const {name: timeName} = timeOptions.find(item => item.id === time) || {};
  // const {name: departmentName = ''} = departments.find(item => item.id === parseInt(department)) || {};
  // const locationName = getArrayVals(location, locations);
  const departmentName = getArrayVals(department, departments);

  let ret = [];
  // if (options.remote !== false) ret.push(remoteName);
  if (options.department !== false) ret.push(departmentName);
  // if (options.location !== false) ret.push(locationName);
  if (options.time !== false) ret.push(timeName);

  return ret.join(', ');
};

const getArrayVals = (vals, options) => {
  if (Array.isArray(vals)) {
    return vals.map(val => options.find(item => item.id === parseInt(val)))
      .filter(item => item)
      .map(item => item.name)
      .join(', ');
  }
  const item = options.find(item => item.id === parseInt(vals));
  return item ? item.name : '';
};
