import React, {useState} from 'react';
import {get, post, dodelete, put} from 'lib/comms';
import {Redirect} from 'react-router-dom';
import {InputDialog, DeleteDialog, MessageDialog} from 'components/Dialog/Dialog';
import {EditDropDown} from 'components/EditText/EditText';
import TextField, {Input} from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import Widgets from './Widgets';
import {DndProvider} from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import saveXLS, {saveXLSData, resetXLSData} from './exportXLS';

export default class DashboardPage extends React.Component {
  constructor (props) {
    super(props);
    this.xlsData = [];
    this.state = {dashboards: [], search: '', editing: null, loading: true, selected: '', editingLayout: false};
  }

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get('cms/dashboards', null, true);
    if (resp && resp.status === 401) this.setState({logout: true});
    if (resp && resp.dashboards) {
      let {selected} = this.state;
      let {dashboards = [], departments = [], locations = []} = resp;
      departments = [{id: 0, name: 'All departments'}, ...departments];
      locations = [{id: 0, name: 'All locations'}, ...(locations || [])];
      if (!selected) selected = (dashboards.find(dashboard => dashboard.default)).name;
      this.setState({dashboards, departments, locations, selected, loading: false});
    } else console.log(resp);
  }

  addNewDasboard = async (name) => {
    const {
      message,
      dashboards = this.state.dashboards
    } = await post('cms/dashboards', {name}, true);

    if (message) {
      this.setState({
        showMessage: {
          title: 'Unable to Add',
          message
        },
        addingDashboard: false
      });
    } else {
      this.setState({
        addingDashboard: false,
        editingLayout: true,
        dashboards: dashboards.map(dashboard => {
          return {
            ...dashboard,
            name: dashboard.name === '' ? ' ' : dashboard.name
          };
        }),
        selected: name
      });
    }
  }

  doDeleteDashboard = async () => {
    const {success} = await dodelete('cms/dashboards', {name: this.state.selected}, true);
    if (!success) return this.setState({deletingDashboard: false, showMessage: {title: 'Error', message: 'Unable to delete dashboard'}});

    let dashboards = this.state.dashboards.filter(item => item.name !== this.state.selected);
    const selected = (dashboards.find(dashboard => dashboard.default) || {}).name;
    this.setState({deletingDashboard: false, dashboards, selected});
  }

  doEdit = async (newval) => {
    let {selected, dashboards} = this.state;
    const response = await put('cms/dashboards', {name: selected, ...newval}, true);
    const {success, message} = response;
    if (!success) return this.setState({deletingDashboard: false, showMessage: {title: 'Error', message: message || 'Unable to edit dashboard'}});

    dashboards = dashboards.map(dashboard => {
      const isSelected = dashboard.name === selected;
      if (newval.newdefault) dashboard.default = isSelected;
      if (newval.newname && isSelected) {
        dashboard.name = newval.newname;
        selected = newval.newname;
      }
      return dashboard;
    });
    this.setState({dashboards, selected});
  }

  setDefaultDashboard = () => {
    if (this.state.editingLayout) this.setState({showMessage: {message: 'Please finish editing first', title: 'Save changes'}});
    else this.doEdit({newdefault: 1, widgets: '{}'});
  }

  // Editing widget layouts:
  editWidgets = (data) => {
    let {dashboards, selected} = this.state;
    const index = dashboards.findIndex(dashboard => dashboard.name === selected);
    let {widgets} = dashboards[index];
    if (typeof widgets === 'string') widgets = JSON.parse(widgets);
    if (widgets || data.type === 'add') {
      if (data.type === 'move') {
        const moveItem = widgets[data.fromIndex];
        widgets.splice(data.fromIndex, 1);
        widgets.splice(data.toIndex, 0, moveItem);
      }
      if (data.type === 'delete') widgets = widgets.filter(widget => widget.id !== data.id);
      if (data.type === 'settings') {
        const widgetIndex = widgets.findIndex(widget => widget.id === data.id);
        widgets[widgetIndex].settings = data.settings;
      }
      if (data.type === 'thresholds') {
        const widgetIndex = widgets.findIndex(widget => widget.id === data.id);
        widgets[widgetIndex].thresholds = data.thresholds;
      }
      if (data.type === 'resize') widgets[data.index].width = data.width;
      if (data.type === 'add') {
        if (!widgets) widgets = [];
        const id = widgets.length ? Math.max(...(widgets.map(w => w.id))) + 1 : 1;
        widgets.push({id, type: data.addType, width: 1});
      }

      dashboards[index].widgets = JSON.stringify(widgets);
      put('cms/dashboards', {name: selected, widgets: dashboards[index].widgets}, true);
      this.setState({dashboards});
    }
  }
  onRearrange = (fromIndex, toIndex) => this.editWidgets({type: 'move', fromIndex, toIndex});
  onDeleteWidget = (id) => this.editWidgets({type: 'delete', id});
  setWidth = (index, width) => this.editWidgets({type: 'resize', index, width});
  saveSettings = (id, settings) => this.editWidgets({type: 'settings', id, settings});
  saveThresholds = (id, thresholds) => this.editWidgets({type: 'thresholds', id, thresholds});
  toggleEditLayout = () => {
    const isediting = this.state.editingLayout;
    this.setState({editingLayout: !isediting}, async () => {
      if (isediting) {
        const {dashboards, selected} = this.state;
        const {widgets} = dashboards.find(dashboard => dashboard.name === selected);
        await put('cms/dashboards', {name: selected, widgets}, true);
      }
    });
  }

  saveXLSData = (index, data) => this.xlsData[index] = data;
  exportXLS = () => saveXLS(this.state.dashboards, this.state.selected, this.xlsData);

  render () {
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}} />;
    let {dashboards, selected, addingDashboard, deletingDashboard, showMessage, editingLayout, departments, locations} = this.state;

    // set to default if we don't have a valid selected
    let dashboard = dashboards.find(dashboard => dashboard.name === selected);
    if (!dashboard) dashboard = (dashboards.find(dashboard => dashboard.default) || {});

    // const {points = [], usercount = 1, appsessions = [], learnings = {}, pulses = []} = data;
    // const top3 = points.slice(0, 3);
    // const totalPoints = points.reduce((total, user) => total + user.points, 0);
    return (
      <div className="dashboardcontainer">
        <TopRow onResetXLS={resetXLSData} onUpdateState={(newState) => this.setState(editingLayout ? {showMessage: {message: 'Please finish editing first', title: 'Save changes'}} : newState)} selected={selected} dashboards={dashboards} onSetDefault={this.setDefaultDashboard} />
        <hr />
        <TitleRow selected={dashboard.name} onExport={this.exportXLS} editingLayout={editingLayout} onRename={(dashboardName) => this.doEdit({newname: dashboardName})} onEdit={this.toggleEditLayout} />
        <DndProvider backend={Backend}>
          <Widgets onXLSData={saveXLSData} widgets={dashboard.widgets || []} {...{editingLayout, departments, locations}} onRearrange={this.onRearrange} onDeleteWidget={this.onDeleteWidget} onSaveSettings={this.saveSettings} onSaveThresholds={this.saveThresholds} setWidth={this.setWidth} addNewWidget={(addType) => this.editWidgets({type: 'add', addType})} />
        </DndProvider>
        {addingDashboard ? <InputDialog title="Create New Dashboard" hint="Dashboard Name" buttonText="CREATE" onClose={() => this.setState({addingDashboard: false})} onSuccess={this.addNewDasboard} /> : null}
        {deletingDashboard ? <DeleteDialog item='Dashboard' onSuccess={this.doDeleteDashboard} onClose={() => this.setState({deletingDashboard: false})} /> : null}
        {showMessage ? <MessageDialog message={showMessage.message} title={showMessage.title} onClose={() => this.setState({showMessage: null})} /> : null}
      </div>
    );
  }
}

const TopRow = ({dashboards = [], selected, onUpdateState, onSetDefault, onResetXLS}) => {
  const doDelete = () => {
    const deleting = dashboards.find(item => item.name === selected);
    if (!deleting) return onUpdateState({showMessage: {title: 'Error', message: 'Something went wrong, can\'t find the dashboard to delete.'}});
    if (deleting.default) return onUpdateState({showMessage: {title: 'Delete', message: 'You can\'t delete the default dashboard.'}});
    onUpdateState({deletingDashboard: true});
  };

  const options = dashboards.map(item => ({id: item.name, name: item.name + (item.default ? ' (default)' : '')}));
  const thisDashboard = dashboards.find(item => item.name === selected) || {};
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row'}}>
      <div>
        <div style={{display: 'inline'}}>
          <EditDropDown label="Select Dashboard" options={options} editing={true} onChange={selected => onResetXLS() && onUpdateState({selected})} value={selected} style={{width: 340, backgroundColor: '#FFF', marginRight: 10, height: 50}} />
        </div>
        <button onClick={doDelete} className="card-edit text-button" style={{color: thisDashboard.default ? '#999' : null}}>Delete</button> | <button onClick={onSetDefault} className="card-edit text-button" style={{color: thisDashboard.default ? '#999' : null}}>Set Default</button>
      </div>
      <div>
        <button onClick={() => onUpdateState({addingDashboard: true})} className="card-edit text-button">Create New Dashboard</button>
      </div>
    </div>
  );
};

const TitleRow = ({selected, onRename, onEdit, editingLayout, onExport}) => {
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(selected);
  React.useEffect(() => {
    setName(selected);
  }, [selected]);

  return (
    <div className='mb-1' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row'}}>
      <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'row'}}>
        {editing && !editingLayout ? (
          <>
            <TextField className="mt-2" label='Dashboard name' outlined style={{width: 340, backgroundColor: '#FFF', height: 50}}>
              <Input className="poppins" value={name} onChange={e => setName(e.target.value)} />
            </TextField>
            <button onClick={async () => {
              setEditing(false);
              if (name && name !== selected) await onRename(name);
              setName(selected);
            }} className="card-edit text-button ml-2 button">OK</button>
          </>
        ) : (
          <>
            <h2 style={{margin: 0}}>{name || 'Dashboard'}</h2>
            {editingLayout ? null : <button onClick={() => setEditing(true)} className="card-edit text-button mb-2 ml-2">Rename</button>}
          </>

        )}
      </div>
      <div>
        {editing && !editingLayout ? null : <button onClick={onExport} className="text-button mr-2 button">EXPORT</button>}
        {editing ? null : <button onClick={onEdit} className="btn btn-primary mb-2 ml-2">{editingLayout ? 'SAVE CHANGES' : 'CHANGE LAYOUT'}</button>}
      </div>
    </div>
  );
};
