import React from 'react';
import './SwitchBar.css';

export default (props) => {
  const {options, values, value, editing} = props;
  const selected = Math.max(values.indexOf(value), 0);
  const className = `${props.className} switchbar-outer ${editing ? 'editing' : 'notediting'}`;
  return (
    <div className={className}>
      {options.map((option, index) =>
        <div key={values[index]} className={`switchbar-option ${index === selected ? 'selected' : ''}`} onClick={editing && props.onChange ? () => props.onChange(values[index]) : null}>{option}</div>
      )}
    </div>
  );
};
