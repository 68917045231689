import React, {useState, useEffect, useContext} from 'react';
import {post} from 'lib/comms';
import {Redirect} from 'react-router-dom';
import logoImage from 'assets/logo_light.png';
import welcomeImage from 'assets/welcome.png';
import './LoginPage.css';
import {UserContext} from '../../contexts/UserContext';
import {getData} from '../../lib/data';

export default (props) => {
  const [status, setStatus] = useState('checking'); // checking, success, fail
  const {setToken} = useContext(UserContext);

  useEffect(() => {
    const onMount = async () => {
      let token = null;

      try {
        let [, , linkToken] = props.location.pathname.split('/');
        linkToken = decodeURIComponent(linkToken);
        let payloadToken = linkToken || '';
        if (payloadToken.indexOf('.') < 0) payloadToken = atob(payloadToken);
        const [, stuff] = payloadToken.split('.');
        const decodedStuff = atob(stuff);
        const payload = JSON.parse(decodedStuff); // all this to get the email address as we may have closed the session
        const resp = await post('authentication/token_authentication', {email_address: payload.email, device_key: getData('deviceKey', 'cms'), token: linkToken});
        if (resp && resp.success && resp.key) token = resp.key;
        else console.log(resp);
      } catch (err) {
        console.log(err);
      }
      if (token) {
        setStatus('success');
        setToken(token);
      } else {
        setStatus('fail');
      }
    };

    onMount();
  }, []);

  if (status === 'success') {
    return (
      <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    );
  }

  const introText = {checking: 'We\'re just checking that the link you clicked is valid...', fail: 'Sorry, but that link is invalid'};

  return (
    <div className="login-outer">
      <div className="login-innerleft">
        <div className="login-content">
          <img src={logoImage} alt="Logo" className="login-logo"/>
          <h3 className="login-title mt-5">Administration Console</h3>
          <h5 className="grey" style={{marginTop: '-10px'}}>{introText[status]}</h5>
        </div>
      </div>

      <div className="login-innerright">
        <img src={welcomeImage} alt="Welcome" className="login-imageright"/>;
      </div>
    </div>
  );
};

// export default class TokenPage extends React.Component {
//   constructor (props) {
//     super(props);
//     this.state = {status: 'checking'};
//   }

//   componentDidMount = async () => {

//   }
// }
