import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone'; // https://github.com/react-dropzone/react-dropzone
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperclip} from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  const [error, setError] = useState('');

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length !== 1) return setError('Only one file please!');
    const [{name, size}] = acceptedFiles;
    const ext = name.toLowerCase().split('.').pop();
    if (ext !== 'csv' && ext !== 'txt') return setError('Please upload a .csv or .txt file!');
    if (size > 2000000) return setError('Please upload a smaller list!');
    const reader = new FileReader();
    reader.onload = () => {
      const results = reader.result.split('\n')
        .map(item => item.trim())
        .map(item => getFirstCSVItem(item))
        .filter(item => validateEmail(item));
      if (results.length > 1000) return setError('Please upload a smaller list, 1000 users is the maximum.');
      props.onSuccess(results);
    };
    reader.readAsText(acceptedFiles[0]);
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  if (isDragActive && error) setError('');
  const text = isDragActive ? 'Drop the csv here!' : 'Attach a csv file containing a list of email addresses.';
  return (
    <div className="editcsv-outer">
      <div {...getRootProps({className: 'editcsv-dropzone'})} >
        <input {...getInputProps()} />
        <div className="editcsv-paperclip mb-2">
          <FontAwesomeIcon icon={faPaperclip}/>
        </div>
        <p className="editcsv-text mb-0">{text}</p>
        {error ? <p style={{color: '#800', margin: 0}}>{error}</p> : null}
      </div>
    </div>
  );
};

function getFirstCSVItem (item) {
  const [splitted] = item.split(/[^A-Za-z0-9.@+!#$%&_-]/);
  return splitted;
}

function validateEmail (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // eslint-disable-line 
  return re.test(String(email).toLowerCase());
}
