import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import Table from 'components/Table/Table';
import ChallengePage from './ChallengePage';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {get} from 'lib/comms';
import {Redirect} from 'react-router-dom';

const columns = [
  {name: 'Name of the Challenge', grow: 7, selector: 'name', sortable: true, wrap: true},
  {name: 'Start date', grow: 2, selector: 'start_date', sortable: true, format: row => moment(row.start_date).format('DD MMM YYYY')},
  {name: 'Participants', selector: 'member_count', sortable: true, center: true},
  {name: 'Points value', grow: 2, selector: 'effort_level', sortable: true, center: true, format: row => row.effort_level * 50},
  {name: 'Status', selector: 'active', sortable: true, center: true, format: row => row.active ? 'Active' : 'Archived'},
  {name: '', selector: 'id', sortable: false, grow: 0, right: true, cell: () => <FontAwesomeIcon color='#999' icon={faChevronRight}/>}
];

export default class ChallengesPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {data: []};
  }

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get('cms/challenges', null, true);
    if (resp && resp.status === 401) this.setState({logout: true});
    if (resp && resp.challenges) this.setState({data: resp.challenges});
    else console.log(resp);
  }

  doneEditing = () => {
    this.setState({editing: null});
    this.reload();
  }

  render () {
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}}/>;
    let {data, search, editing} = this.state;
    if (editing) return <ChallengePage challenge={editing} onBack={this.doneEditing}/>;
    if (search) data = data.filter(item => `${item.name} ${item.category}`.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    return (
      <div className="maincontainer">
        <h4>Challenges</h4>
        <SearchRow value={search} searchTitle='Search by challenge name' buttonTitle='ADD NEW CHALLENGE' onBtnClick={(item) => this.setState({editing: {id: 'new'}})} onSearch={(search) => this.setState({search})}/>
        <Table columns={columns} data={data} onRowClicked={(item) => this.setState({editing: item})} tableID='challenges' defaultSortField={columns[1].selector}/>
      </div>
    );
  }
}
