import React, {useContext} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Menu from './menu/Menu';
import DashboardPage from './pages/Dashboard/DashboardPage';
import UsersPage from './pages/Users/UsersPage';
import GroupsPage from './pages/Groups/GroupsPage';
import DepartmentsPage from './pages/Departments/DepartmentsPage';
import TagsPage from './pages/Tags/TagsPage';
import MicroLearningsPage from './pages/MicroLearning/MicroLearningsPage';
import ContentsPage from './pages/Contents/ContentsPage';
import ChallengesPage from './pages/Challenges/ChallengesPage';
import BillingPage from './pages/Billing/BillingPage';
import OrgsPage from './pages/Orgs/OrgsPage';
import './MainPage.css';
import NoticesPage from './pages/Notices/NoticesPage';
import {UserContext} from '../../contexts/UserContext';
import SettingsPage from './pages/Settings/SettingsPage';
import ReportsPage from './pages/Reports/ReportsPage';

const MainPage = (props) => {
  const {user, token} = useContext(UserContext);

  if (!(user && token)) {
    return (
      <Redirect
        to={{pathname: '/login', state: {from: props.location}}}
      />
    );
  }

  return (
    <div
      style={{display: 'flex', flexDirection: 'row', minHeight: '100vh'}}
    >
      <Menu user={user} />
      <div style={{flex: 1, backgroundColor: '#F4F4F4'}}>
        <Switch>
          <Route path="/dashboard" component={DashboardPage} />
          <Route exact path="/users" component={UsersPage} />
          <Route path="/users/list" component={UsersPage} />
          <Route path="/users/tags" component={TagsPage} />
          {user.is_global && <Route path="/notices" component={NoticesPage} />}
          <Route path="/departments" component={DepartmentsPage} />
          <Route path="/groups" component={GroupsPage} />
          <Route path="/micro" component={MicroLearningsPage} />
          <Route path="/content" component={ContentsPage} />
          <Route path="/challenges" component={ChallengesPage} />
          <Route path="/billing" component={BillingPage} />
          <Route path="/reports" component={ReportsPage} />
          <Route path="/settings" component={SettingsPage} />
          {user.master_admin ? (
            <Route path="/orgs" component={OrgsPage} />
          ) : null}
          <Route
            path="/"
            render={() => (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: {from: props.location}
                }}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default MainPage;

// export default class MainPage extends React.Component {
//   constructor (props) {
//     super(props);
//     this.state = {token: getData('token', '', true)};
//   }

//   logout = renderProps => {
//     deleteData('token', '', true);
//     return (
//       <Redirect to={{pathname: '/', state: {from: this.props.location}}} />
//     );
//   };

//   render () {
//     if (!this.state.token) {
//       return (
//         <Redirect
//           to={{pathname: '/login', state: {from: this.props.location}}}
//         />
//       );
//     }
//     const user = getUserFromToken(this.state.token);

//     return (
//       <div
//         style={{display: 'flex', flexDirection: 'row', minHeight: '100vh'}}
//       >
//         <Menu user={user} />
//         <div style={{flex: 1, backgroundColor: '#F4F4F4'}}>
//           <Switch>
//             <Route path="/dashboard" component={DashboardPage} />
//             <Route exact path="/users" component={UsersPage} />
//             <Route path="/users/list" component={UsersPage} />
//             <Route path="/users/tags" component={TagsPage} />
//             {user.is_global && <Route path="/notices" component={NoticesPage} />}
//             <Route path="/departments" component={DepartmentsPage} />
//             <Route path="/groups" component={GroupsPage} />
//             <Route path="/micro" component={MicroLearningsPage} />
//             <Route path="/content" component={ContentsPage} />
//             <Route path="/challenges" component={ChallengesPage} />
//             <Route path="/billing" component={BillingPage} />
//             {user.master_admin ? (
//               <Route path="/orgs" component={OrgsPage} />
//             ) : null}
//             <Route
//               path="/"
//               render={() => (
//                 <Redirect
//                   to={{
//                     pathname: '/dashboard',
//                     state: {from: this.props.location}
//                   }}
//                 />
//               )}
//             />
//           </Switch>
//         </div>
//       </div>
//     );
//   }
// }
