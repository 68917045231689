import React from 'react';

export default class BillingPage extends React.Component {
  render () {
    return (
      <div className="maincontainer">
        <h4>Billing</h4>
        <p>Billing section coming soon...</p>
      </div>
    );
  }
}
