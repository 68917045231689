import React, {useState} from 'react';
import './EditImage.css';
import {useDropzone} from 'react-dropzone'; // https://github.com/react-dropzone/react-dropzone
import {upload} from 'lib/comms';
import AuthImage from 'components/AuthImage/AuthImage';
import {DeleteDialog} from 'components/Dialog/Dialog';
import placeholder from 'assets/placeholder.png';

export const EditImage = (props) => {
  const [uploading, setUploading] = useState(false);
  const [imageid, setImageId] = useState(props.imageid);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onDropped = imageId => {
    setImageId(imageId);
    setUploading(false);
    props.onChange(imageId);
  };

  const onDelete = () => {
    setImageId(null);
    setUploading(false);
    props.onChange(null);
    setShowDeleteDialog(false);
  };

  let {editing} = props;
  if (!editing && uploading) setUploading(false);
  return (
    <div className="editimage-outer">
      {uploading ? <MyDropzone onSuccess={onDropped}></MyDropzone> : imageid ? <AuthImage url={`content/image/${imageid}`} className="editimage-img" alt=""/> : <img src={placeholder} className="editimage-img" alt=""/>}
      <button className="btn btn-primary mt-3" disabled={editing ? '' : 'disaibled'} onClick={() => setUploading(!uploading)}>{uploading ? 'CANCEL' : imageid ? 'REPLACE IMAGE' : 'ADD IMAGE'}</button>
      <button className="btn btn-outline-primary mt-2" disabled={editing && imageid && !uploading ? '' : 'disabled'} onClick={() => setShowDeleteDialog(true)}>DELETE IMAGE</button>
      {showDeleteDialog ? <DeleteDialog item='Image' onSuccess={onDelete} onClose={() => setShowDeleteDialog(false)}/> : null}

    </div>
  );
};

function MyDropzone (props) {
  const [error, setError] = useState('');

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length !== 1) return setError('Only one file please!');
    const [{name, size}] = acceptedFiles;
    if (size > 4000000) return setError('Please upload a smaller image!');
    const ext = name.toLowerCase().split('.').pop();
    if (ext !== 'jpg' && ext !== 'png') return setError('Please upload a .jpg or .png file!');
    const resp = await upload('cms/upload', 'image', acceptedFiles[0], true);
    if (resp && resp.image_id && props.onSuccess) props.onSuccess(resp.image_id);
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  if (isDragActive && error) setError('');
  const text = isDragActive ? 'Drop the image here!' : 'Drag and drop an image, or click to select file.';
  return (
    <div {...getRootProps({className: 'editimage-dropzone'})} >
      <input {...getInputProps()} />
      <p>{text}</p>
      {error ? <p style={{color: '#800'}}>{error}</p> : null}
    </div>
  );
}
