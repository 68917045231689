import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import Card from 'components/Card/Card';
import {EditText, EditDropDown, EditTextArea, EditDate} from 'components/EditText/EditText';
import {get, post, dodelete} from 'lib/comms';
import {DeleteDialog, MessageDialog} from 'components/Dialog/Dialog';
import SearchRow from 'components/SearchRow/SearchRow';
import Table from 'components/Table/Table';
import SwitchBar from 'components/SwitchBar/SwitchBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import './ChallengePage.css';
import moment from 'moment';

export default class GroupPage extends React.Component {
  columns = [
    {name: 'Name', grow: 2, selector: 'last_name', sortable: true, format: row => `${row.first_name || ''} ${row.last_name || ''} ${row.deleted_at ? ' (deleted)' : ''}`},
    {name: 'Email', grow: 2, selector: 'email_address', sortable: true},
    {name: '', selector: 'id', sortable: false, right: true, cell: row => <button className="group-remove-member-btn" onClick={() => this.deleteMember(row)}><FontAwesomeIcon icon={faMinusCircle}/> Remove Member</button>}
  ];
  columns2 = [
    {name: 'Name', grow: 2, selector: 'last_name', sortable: true, format: row => `${row.first_name || ''} ${row.last_name || ''}`},
    {name: 'Email', grow: 2, selector: 'email_address', sortable: true},
    {name: '', selector: 'id', sortable: false, right: true, cell: row => <button className="group-remove-member-btn group-add-member-btn" onClick={() => this.addMember(row)}><FontAwesomeIcon icon={faPlusCircle}/> Add Member</button>}
  ];

  constructor (props) {
    super(props);
    const isNew = props.challenge && props.challenge.id === 'new';
    const challenge = isNew ? {id: 'new', description: '', name: '', start_date: moment().format('YYYY-MM-DD'), end_date: moment().add(1, 'month').format('YYYY-MM-DD'), effort_level: 1, target_count: 1} : props.challenge;
    this.state = {challenge, editing: [isNew], showDeleteDialog: false, showMessage: null, challengemembers: [], nonchallengemembers: [], addingMembers: isNew, isNew};
  }

  setEditing = async (index) => {
    let {editing, challenge} = this.state;
    if (editing[index]) { // save to db
      if (!challenge.name) return this.setState({showMessage: {message: 'Please include a valid title', title: 'Save challenge'}});
      if (!challenge.description) return this.setState({showMessage: {message: 'Please include a valid description', title: 'Save challenge'}});
      const resp = await post('cms/challenges', {challenge}, true);
      if (resp && resp.success) {
        editing[index] = false;
        if (resp.id) {
          challenge.id = resp.id;
          this.setState({challenge, isNew: false});
        }
      } else console.log(resp);
      if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error'}}), 500);
    } else editing[index] = true;
    this.setState({editing});
  }

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get(`cms/challengemembers/${this.state.challenge.id}`, null, true);
    if (resp && resp.challengemembers) this.setState({challengemembers: resp.challengemembers});
    else console.log(resp);
    // now get the list of users that are not members so we can add them
    resp = await get(`cms/challengemembers/${this.state.challenge.id}?nonmembers=1`, null, true);
    if (resp && resp.nonchallengemembers) this.setState({nonchallengemembers: resp.nonchallengemembers});
    else console.log(resp);
  }

  doDelete = async () => {
    const resp = await dodelete('cms/challenges', {id: this.state.challenge.id}, true);
    await this.setState({showDeleteDialog: false});
    if (resp && resp.success && this.props.onBack) this.props.onBack();
    else if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Delete challenge'}}), 500);
  }

  deleteMember = async (member) => {
    const resp = await dodelete(`cms/challengemembers/${this.state.challenge.id}`, {id: member.user_id}, true);
    if (resp.success) {
      const challengemembers = this.state.challengemembers.filter(challengemember => challengemember.user_id !== member.user_id);
      const nonchallengemembers = [...this.state.nonchallengemembers, member];
      this.setState({challengemembers, nonchallengemembers});
    }
    if (resp.message) this.setState({showMessage: {message: resp.message, title: 'Remove memeber'}});
  }

  addMember = async (member) => {
    const resp = await post(`cms/challengemembers/${this.state.challenge.id}`, {id: member.user_id}, true);
    if (resp.success) {
      const nonchallengemembers = this.state.nonchallengemembers.filter(challengemember => challengemember.user_id !== member.user_id);
      const challengemembers = [...this.state.challengemembers, member];
      this.setState({challengemembers, nonchallengemembers});
    }
    if (resp.message) this.setState({showMessage: {message: resp.message, title: 'Remove memeber'}});
  }

  render () {
    let {challenge, editing, search, challengemembers, nonchallengemembers, addingMembers} = this.state;
    let tableData = addingMembers ? nonchallengemembers : challengemembers;
    if (search) tableData = tableData.filter(item => `${item.first_name} ${item.last_name} ${item.email_address}`.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    return (
      <div className="maincontainer">
        <BackRow onBack={this.props.onBack} buttonTitle={this.state.isNew ? null : 'DELETE CHALLENGE'} onDelete={() => this.setState({showDeleteDialog: true})}/>
        <Card title="Challenge information" editing={editing[0] ? '1' : undefined} onToggle={() => this.setEditing(0)}>
          <div className="challenge-edit-outer">
            <div className="challenge-edit-inner-left">
              <SwitchBar className="mb-4" editing={editing[0] ? '1' : undefined} options={['Active', 'Archived']} values={[1, 0]} value={challenge.active} onChange={value => this.setState({challenge: {...challenge, active: value}})}/>
              <EditText label="Challenge Name" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({challenge: {...challenge, name: text}})} value={challenge.name}/>
              <EditTextArea label="Challenge Description" editing={editing[0] ? '1' : undefined} onChange={text => this.setState({challenge: {...challenge, description: text}})} value={challenge.description}/>
            </div>
            <div className="challenge-edit-inner-divider"></div>
            <div className="challenge-edit-inner-right">
              <div className="challenge-double-date">
                <EditDate className="mr-2" label="Start Date" editing={editing[0] ? '1' : undefined} onChange={date => this.setState({challenge: {...challenge, start_date: date}})} value={challenge.start_date}/>
                <EditDate className="ml-2" label="End Date" editing={editing[0] ? '1' : undefined} onChange={date => this.setState({challenge: {...challenge, end_date: date}})} value={challenge.end_date}/>
              </div>
              <EditDropDown className="mb-0" label="Effort Level" options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} editing={editing[0] ? '1' : undefined} onChange={id => this.setState({challenge: {...challenge, effort_level: id}})} value={challenge.effort_level}/>
              <p className="edittext-help">The effort level is measured as a range between 1 and 10. 1 being the lowest level of effort and 10 being the highest.</p>
              <EditDropDown className="mb-0" label="Target number" options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]} editing={editing[0] ? '1' : undefined} onChange={id => this.setState({challenge: {...challenge, target_count: id}})} value={challenge.target_count}/>
              <p className="edittext-help">Enter how many times this achievement should be achieved.</p>
              <div className="challenge-double-date mt-2">
                <p className="">Points Value: <b>{(challenge.effort_level || 1) * 50}</b></p>
                <p className="">Participants: <b>{challengemembers.length}</b></p>
              </div>
            </div>
          </div>
        </Card>
        {this.state.isNew ? null : (
          <div className="mt-5">
            <SearchRow title="Participants" value={search} searchTitle='Search by name or email address' onSearch={(search) => this.setState({search})} className={addingMembers ? 'success' : 'primary'} buttonTitle={addingMembers ? 'DONE' : 'ADD MEMBERS'} onBtnClick={() => this.setState({addingMembers: !addingMembers, search: ''})} />
            <Table columns={addingMembers ? this.columns2 : this.columns} data={tableData} noSelect tableID='challenge'/>
          </div>
        )}
        {this.state.showDeleteDialog ? <DeleteDialog item='Challenge' onSuccess={this.doDelete} onClose={() => this.setState({showDeleteDialog: false})}/> : null}
        {this.state.showMessage ? <MessageDialog message={this.state.showMessage.message} title={this.state.showMessage.title} onClose={() => this.setState({showMessage: null})}/> : null}

      </div>
    );
  }
}
