import React from 'react';
import './BackRow.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faTrashAlt} from '@fortawesome/free-solid-svg-icons';

export default (props) => (
  <div className="backrow-outer">
    <button className="backrow-back" onClick={props.onBack}><FontAwesomeIcon icon={faChevronLeft} /> Back</button>
    <div className='backrow-btn-outer'>
      {props.buttonTitle ? <button className="btn btn-danger backrow-btn" onClick={props.onDelete}><FontAwesomeIcon icon={faTrashAlt} style={{marginRight: '10px'}}/> {props.buttonTitle}</button> : null}
    </div>
  </div>
);
