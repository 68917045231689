import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import {get} from 'lib/comms';
import Table from 'components/Table/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import OrgPage from './OrgPage';
import moment from 'moment';
import {Redirect} from 'react-router-dom';

const columns = [
  {name: 'Organisation Name', selector: 'company_name', sortable: true, grow: 3, wrap: true},
  {name: 'Points Cap', selector: 'points_cap', center: true, sortable: true},
  {name: 'Created', selector: 'created_at', sortable: true, format: row => row.created_at ? moment(row.created_at).format('DD MMM YYYY HH:mm') : ''},
  {name: 'No. of Members', selector: 'member_count', center: true, sortable: true},
  {name: '', selector: 'id', sortable: false, grow: 0, right: true, cell: () => <FontAwesomeIcon color='#999' icon={faChevronRight}/>}
];
export default class GroupsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {data: [], search: '', editing: null};
  }

  componentDidMount = () => this.reload();

  doneEditing = () => {
    this.setState({editing: null});
    this.reload();
  }

  reload = async () => {
    let resp = await get('cms/organisations', null, true);
    if (resp && resp.status === 401) this.setState({logout: true});
    if (resp && resp.organisations) this.setState({data: resp.organisations});
    else console.log(resp);
  }

  render () {
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}}/>;
    let {data, search, editing} = this.state;
    if (editing) return <OrgPage organisation={editing} onBack={this.doneEditing}/>;
    if (search) data = data.filter(item => item.company_name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    return (
      <div className="maincontainer">
        <h4>Organisation Management</h4>
        <SearchRow value={search} searchTitle='Search by organisation name' onSearch={(search) => this.setState({search})} buttonTitle='ADD NEW ORGANISATION' onBtnClick={(item) => this.setState({editing: {id: 'new', points_cap: 600}})}/>
        <Table columns={columns} data={data} onRowClicked={(item) => this.setState({editing: item})} tableID='organisations' defaultSortField={columns[0].selector}/>
      </div>
    );
  }
}
