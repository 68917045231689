import {getData} from 'lib/data';
export const server = process.env.REACT_APP_API_URL;
// process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_LIVE;

export const get = async (url, params, includetoken) => {
  if (params) {
    let paramArray = [];
    for (var k in params) paramArray.push(`${k}=${encodeURIComponent(params[k])}`);
    url += `?${paramArray.join('&')}`;
  }
  return sendRequest(url, {method: 'get', headers: {cache: 'force-cache'}}, includetoken);
};

export const getBlob = async (url) => {
  const headers = {cache: 'force-cache', Authorization: 'Bearer ' + getData('token', ''), 'x-application-platform': 'cms', 'x-application-version': '1.0.0'};
  var resp = await fetch(`${server}/${url}`, {headers});
  return resp.blob();
};

export const post = (url, params, includetoken) => sendRequest(url, {method: 'post', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);

export const upload = (url, paramName, file, includetoken) => {
  const formData = new FormData();
  formData.append(paramName, file);
  return sendRequest(url, {method: 'post', body: formData}, includetoken);
};
export const dodelete = (url, params, includetoken) => sendRequest(url, {method: 'delete', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);
export const put = (url, params, includetoken) => sendRequest(url, {method: 'put', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);
export const patch = (url, params, includetoken) => sendRequest(url, {method: 'PATCH', headers: {'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify(params)}, includetoken);

async function sendRequest (url, stuff, includetoken) {
  try {
    if (includetoken) stuff.headers = {...stuff.headers, Authorization: 'Bearer ' + getData('token', '')};
    stuff.headers = {...stuff.headers, 'x-application-platform': 'cms', 'x-application-version': '1.0.0'};
    // stuff.mode = 'no-cors';
    var resp = await fetch(`${server}/${url}`, stuff);
    const respJson = await resp.json();
    return {status: resp.status, ...respJson};
  } catch (err) {
    console.log(url);
    console.log(err);
    return {message: 'There was an error: ' + err.message};
  }
}
