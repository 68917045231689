import React from "react";
import SearchRow from "components/SearchRow/SearchRow";
import Table from "components/Table/Table";
import UserPage from "./UserPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { get, put } from "lib/comms";
import { AddUserDialog, MessageDialog } from "components/Dialog/Dialog";
import { Dot } from "components/SVG/SVG";
import { Redirect } from "react-router-dom";
import qs from "qs";

export default class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: "",
      editing: null,
      addUser: false,
      showMessage: null,
    };
  }

  columns = [
    {
      name: "Name",
      selector: "last_name",
      sortable: true,
      grow: 1,
      wrap: true,
      format: (row) => `${row.first_name || ""} ${row.last_name || ""}`,
    },
    { name: "Email", selector: "email_address", sortable: true },
    {
      name: "Status",
      selector: "first_name",
      sortable: true,
      cell: (row) =>
        row.first_name ? (
          <div onClick={() => this.setState({ editing: row })}>
            <Dot color="#4AB793" /> Registered
          </div>
        ) : (
          <div onClick={() => this.setState({ editing: row })}>
            <Dot color="#AF365F" /> Not Registered
          </div>
        ),
    },
    {
      name: "Permissions",
      selector: "user_level",
      sortable: true,
      cell: (row) =>
        row.user_level ? (
          <div onClick={() => this.setState({ editing: row })}>
            <Dot color="#FBB811" /> Advanced
          </div>
        ) : (
          <div onClick={() => this.setState({ editing: row })}>
            <Dot color="#68C4D1" /> Basic
          </div>
        ),
    },
    {
      name: "",
      selector: "id",
      sortable: false,
      grow: 0,
      right: true,
      cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />,
    },
  ];

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get("cms/users", null, true);
    if (resp && resp.status === 401) this.setState({ logout: true });
    if (resp && resp.users) this.setState({ data: resp.users });
    else console.log(resp);
  };

  doneEditing = () => {
    this.setState({ editing: null });
    this.props.history.push("/users");
    this.reload();
  };

  addUser = async (email) => {
    this.setState({ addUser: false });
    if (Array.isArray(email)) email = email.join(",");
    if (email) {
      let resp = await put("cms/users", { email }, true);
      if (resp.success) {
        let message =
          resp.inserted === 1
            ? "1 email has been added to the system."
            : `${resp.inserted} emails have been added to the system.`;
        if (resp.inserted === 1 && resp.failed === 0)
          message = `${email} has been added to the system.`;
        if (resp.inserted === 0 && resp.failed === 1)
          message = `${email} has not been added to the system, it already exists.`;
        this.setState({ showMessage: { message, title: "Add user" } });
      }
    }
    this.reload();
  };

  static getDerivedStateFromProps = (props, state) => {
    const query = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.user_id) {
      const selectedUser = state.data.find((user) => user.id === query.user_id);
      return {
        ...state,
        editing: selectedUser,
      };
    }

    return state;
  };

  render() {
    if (this.state.logout)
      return (
        <Redirect
          to={{ pathname: "/logout", state: { from: this.props.location } }}
        />
      );
    let { data, search, editing, addUser, showMessage } = this.state;
    if (editing) return <UserPage user={editing} onBack={this.doneEditing} />;
    if (search)
      data = data.filter(
        (item) =>
          `${item.first_name} ${item.last_name} ${item.email_address}`
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
      );
    return (
      <div className="maincontainer">
        <h4>User Management</h4>
        <SearchRow
          searchTitle="Search by name or email address"
          value={search}
          buttonTitle="ADD NEW USER"
          onBtnClick={() => this.setState({ addUser: true })}
          onSearch={(search) => this.setState({ search })}
        />
        <Table
          columns={this.columns}
          data={data}
          onRowClicked={(item) => this.setState({ editing: item })}
          tableID="users"
          defaultSortField={this.columns[1].selector}
        />
        {addUser ? (
          <AddUserDialog
            onClose={() => this.setState({ addUser: false })}
            onSuccess={this.addUser}
          />
        ) : null}
        {showMessage ? (
          <MessageDialog
            message={showMessage.message}
            title={showMessage.title}
            onClose={() => this.setState({ showMessage: null })}
          />
        ) : null}
      </div>
    );
  }
}
