
export function getUserFromToken (token = '') {
  const [, tokenData] = token.split('.');
  if (!tokenData) return {};
  const userText = atob(tokenData);
  try {
    return JSON.parse(userText);
  } catch (err) {
    return {};
  }
}
