import React from 'react';
import {get} from 'lib/comms';
import {FlexibleXYPlot, AreaSeries, LineSeries, MarkSeries, HorizontalGridLines, XAxis, YAxis, Hint} from 'react-vis'; // https://uber.github.io/react-vis/documentation/api-reference/xy-plot
import 'react-vis/dist/style.css';
import moment from 'moment';
import {createDashboardWidgetSettings} from '../../../../../lib/dashboard';
import SmallLoader from '../../../../../components/SmallLoader/SmallLoader';

export default class extends React.Component {
  state = {data: [], status: 'LOADING'};

  componentDidMount = async () => this.reload();

  reload = async () => {
    const {settings} = this.props;

    this.setState({
      status: 'LOADING'
    });

    const {data} = await get('cms/dashboard_data/pulse', createDashboardWidgetSettings(settings), true);

    if (Array.isArray(data)) {
      this.makeXLSPage(data);
      this.setState({
        data,
        status: 'READY'
      });
    }
  }

  makeXLSPage = (data) => {
    // console.log(data);
    // let out = [['First name', 'Surname', 'Email', 'Points']];
    // data.forEach(record => out.push([record.first_name, record.last_name, record.email_address, record.points]));
    // if (this.props.onXLSData) this.props.onXLSData(out);
  }

  render () {
    const {hintItem, data, status} = this.state;

    if (status === 'LOADING') {
      return <div style={localStyle.outer}><SmallLoader /></div>;
    }

    let xlsData = [
      ['Date', 'Avg Pulse']
    ];

    const formattedData = data.map((dayScore, index) => ({
      x: index,
      y: Math.min(dayScore.score, 100),
      color: getColor(dayScore.score)
    }));

    data.forEach((row, index) => {
      xlsData.push([row.dateString.slice(0, 10), row.score]);
    });

    // let x = 0;
    // labels = [];
    // if (time === '6months') {
    //   xlsData.push(['Month', 'Ave Pulse']);
    //   for (let i = 0; i < 6; i++) {
    //     const month = i === 5 ? moment().startOf('month') : moment().add(i - 5, 'months').startOf('month');
    //     const monthEnd = i === 5 ? moment().endOf('month') : moment().add(i - 5, 'months').endOf('month');
    //     const [firstDay, lastDay] = [month.dayOfYear(), monthEnd.dayOfYear()];
    //     let [monthVal, monthCount] = [0, 0];
    //     pulses.forEach(pulse => {
    //       if (pulse.day_of_year >= firstDay && pulse.day_of_year <= lastDay) {
    //         monthVal += pulse.total;
    //         monthCount += pulse.pulses;
    //       }
    //     });
    //     const score = monthVal / (monthCount || 1);
    //     data.push({x: i, y: score, color: getColor(score)});
    //     labels.push(month.format('MMM'));
    //     xlsData.push([month.format('MMMM YYYY'), score]);
    //   }
    // } else {
    //   xlsData.push(['Date', 'Ave Pulse']);
    //   let [startDate, endDate, format] = [moment().startOf('month'), moment().endOf('day'), 'D'];
    //   if (time === '7days') [startDate, format] = [moment().subtract(6, 'days'), width === 1 ? 'ddd' : 'ddd Do'];
    //   if (time === 'prevmonth') [startDate, endDate] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    //   let day = moment(startDate);
    //   while (day.isBefore(endDate)) {
    //     const val = pulses.find(item => item.day_of_year === day.dayOfYear());
    //     const score = val ? val.total / (val.pulses || 1) : 0;
    //     data.push({x: x++, y: score, color: getColor(score)});
    //     labels.push(day.format(format));
    //     xlsData.push([day.format('DD/MM/YYYY'), score]);
    //     day.add(1, 'day');
    //   }
    // }

    if (this.props.onXLSData) this.props.onXLSData(xlsData);

    return (
      <div style={localStyle.outer}>
        <div style={{flex: 1}}>
          <FlexibleXYPlot xType="linear" yDomain={[0, 100]}>
            <HorizontalGridLines />
            <XAxis tickValues={data.map((item, index) => index)} tickFormat={(d, i) => i % Math.max(Math.floor(data.length / 10), 1) === 0 ? moment(data[i].dateString).format('DD/MM') : ''} tickTotal={5}/>
            <YAxis />
            <AreaSeries data={formattedData} curve={'curveMonotoneX'} fill='#CCC' color='#CCC' />
            <LineSeries data={formattedData} curve={'curveMonotoneX'} color='#333' />
            <MarkSeries data={formattedData} size={4} colorRange={['#ff0000', '#00ff00']} colorType="literal" onValueMouseOver={(item) => this.setState({hintItem: item})} onValueMouseOut={() => this.setState({hintItem: null})} />
            {hintItem ? <Hint value={hintItem} style={{fontSize: 14}}><div style={{border: '1px solid ' + hintItem.color, borderWidth: 2, borderRadius: '4px', backgroundColor: '#FFF', padding: '2px 6px', color: '#000', margin: '1px'}}>{hintItem.y.toFixed(0)}</div></Hint> : null}
          </FlexibleXYPlot>
        </div>
      </div>
    );
  }
}

const localStyle = {
  outer: {display: 'flex', flexDirection: 'row', padding: 4, flex: 1}
};

const getColor = (score) => {
  const g = Math.min(score * 5, 255);
  const r = Math.min((100 - score) * 5, 255);
  return `rgb(${r},${g},0)`;
};
