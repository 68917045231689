import React from 'react';
import './Table.css';
import DataTable from 'react-data-table-component';
import {SyncLoader} from 'react-spinners';

const theme = require('./theme');

var pages = {}; // save 'state' of each table, eg pages['users']=2 etc
var rowsPerPage = {};
var sorts = {};

// https://www.npmjs.com/package/react-data-table-component#basic-table

export default (props) => {
  const sort = sorts[props.tableID] || {};
  return (
    <div className="table-outer">
      {
        props.loading
          ? (
            <div className="table__loader">
              <SyncLoader color={'#1874A7'} />
            </div>
          )
          : (
            <DataTable
              className="table-table"
              columns={props.columns}
              data={props.data}
              customTheme={theme}
              highlightOnHover={!props.noSelect}
              pointerOnHover={!props.noSelect}
              pagination
              noHeader
              defaultSortField = {sort.field || props.defaultSortField}
              defaultSortAsc = {!sort.desc}
              paginationDefaultPage={pages[props.tableID] || 1}
              paginationPerPage={rowsPerPage[props.tableID] || 10}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onRowClicked={props.onRowClicked}
              onChangePage={props.tableID ? page => pages[props.tableID] = page : null}
              onChangeRowsPerPage={(rows, page) => {
                if (props.tableID) {
                  pages[props.tableID] = page;
                  rowsPerPage[props.tableID] = rows;
                }
              }}
              onSort = {props.tableID ? (column, direction) => sorts[props.tableID] = {field: column.selector, desc: direction === 'desc'} : null}
            />
          )
      }
    </div>
  );
};
