import React from 'react';
import SearchRow from 'components/SearchRow/SearchRow';
import {get, post} from 'lib/comms';
import Table from 'components/Table/Table';
import DepartmentPage from './DepartmentPage';
import NewDepartmentPage from './NewDepartmentPage';
import './DepartmentsPage.css';
import {Redirect} from 'react-router-dom';

const columns = [
  {name: 'Id', selector: 'id', grow: 0, sortable: false, hide: 4096},
  {name: 'Department', selector: 'department', sortable: true, grow: 3, wrap: true},
  {name: 'Users', selector: 'user_count', sortable: true, grow: 3, wrap: true}
];
export default class DepartmentsPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {data: [], search: '', editing: null, addingDepartment: false, newDepartment: null, showMessage: null};
  }

  componentDidMount = () => this.reload();

  doneEditing = () => {
    this.setState({editing: null});
    this.reload();
  }

  newDepartmentSave = async () => {
    let {newDepartment} = this.state;
    const resp = await post('cms/department/', {new_department_name: newDepartment}, true);
    if (resp && resp.success) {
      this.setState({newDepartment: null, addingDepartment: null});
      this.reload();
    }
    if (resp && resp.message) {
      console.log(resp.message);
      setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error', close: () => this.setState({showMessage: null})}}), 500);
    }
  }

  handleNewDepartmentChange = (value) => {
    this.setState({newDepartment: value});
  }

  reload = async () => {
    let resp = await get('cms/department', null, true);
    if (resp && resp.status === 401) this.setState({logout: true});
    if (resp && resp.departments) this.setState({data: resp.departments});
    else console.log(resp.departments);
  }

  render () {
    const errorMessage = this.state.showMessage;
    const departmentsData = this.state.data;
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}}/>;
    let {data, search, editing, addingDepartment} = this.state;
    if (editing) return <DepartmentPage department={editing} departmentsData={departmentsData} onBack={this.doneEditing}/>;
    if (addingDepartment) return <NewDepartmentPage value={this.state.newDepartment} onEdit={this.handleNewDepartmentChange} submit={this.newDepartmentSave} errorMessage={errorMessage} back={() => this.setState({addingDepartment: null})}/>;
    if (search) data = data.filter(item => item.department.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    return (
      <div className="maincontainer">
        <h4>Department</h4>
        <SearchRow value={search} searchTitle='Search by department name' onSearch={(search) => this.setState({search})} className={addingDepartment ? 'success' : 'primary'} onBtnClick={() => this.setState({addingDepartment: !addingDepartment, search: ''})} buttonTitle={'ADD NEW DEPARTMENT'}/>
        <Table columns={columns} data={data} onRowClicked={(item) => this.setState({editing: item})} tableID='departments' defaultSortField={columns[1].selector}/>
      </div>
    );
  }
}
