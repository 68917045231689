import React, { useContext, useCallback } from "react";
import { NavLink } from "react-router-dom";
import logoImage from "assets/logo_light.png";
import poweredImage from "assets/poweredby.png";
import "./Menu.css";
import { UserContext } from "../../../contexts/UserContext";
import { getData, setData } from "../../../lib/data";

const Menu = () => {
  const { user } = useContext(UserContext);
  const tokenAdmin = getData("token_admin", null);

  const exitSupportMode = useCallback(() => {
    setData("token", tokenAdmin, true);
    window.location = "/";
  }, []);

  return (
    <div className="menu-outer">
      {user.is_supporting && (
        <div className="support-box">
          <h4>Support mode</h4>
          {tokenAdmin ? (
            <button className="btn btn-danger" onClick={exitSupportMode}>
              EXIT SUPPORT MODE
            </button>
          ) : (
            <div>Sign out to exit support mode</div>
          )}
        </div>
      )}
      <img src={logoImage} alt="Logo" className="menu-logo" />
      <NavLink className="menu-navlink" to="/dashboard">
        Dashboard
      </NavLink>
      <NavLink className="menu-navlink" to="/users">
        User Management
        {/* <NavLink className="menu-subnavlink" exact to="/users">- All Users</NavLink>
        <NavLink className="menu-subnavlink" to="/users/tags">- User Tags</NavLink> */}
      </NavLink>

      <NavLink className="menu-navlink" to="/groups">
        Topic Management
      </NavLink>
      {user.is_global && (
        <NavLink className="menu-navlink" to="/notices">
          Notices
        </NavLink>
      )}
      {/* <NavLink className="menu-navlink" to="/micro">Micro-Learning</NavLink>
      <NavLink className="menu-navlink" to="/challenges">Challenges</NavLink> */}
      <NavLink className="menu-navlink" to="/content">
        Onboarding Screens
      </NavLink>
      {/* <NavLink className="menu-navlink" to="/departments">Department</NavLink> */}
      <NavLink className="menu-navlink" to="/reports">
        Reported Posts
      </NavLink>
      {/* <NavLink className="menu-navlink" to="/billing">Billing</NavLink> */}
      <NavLink className="menu-navlink" to="/settings">
        Settings
      </NavLink>
      {user.master_admin && (
        <React.Fragment>
          <hr className="menu-line" />
          <NavLink className="menu-navlink" to="/orgs">
            ⭐️ Organisations
          </NavLink>
        </React.Fragment>
      )}
      <hr className="menu-line" />
      <NavLink className="menu-navlink menu-signout" to="/logout">
        <div className="menu-reddot"></div>
        Sign out
      </NavLink>
      <img src={poweredImage} alt="Logo" className="menu-poweredby" />
    </div>
  );
};

export default Menu;
