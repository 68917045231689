import React from 'react';
import BackRow from 'components/BackRow/BackRow';
import TabRow from 'components/TabRow/TabRow';
import {DeleteDialog, MessageDialog} from 'components/Dialog/Dialog';
import About from './About';
import Questions from './Questions';
import Results from './Results';
import {post, dodelete} from 'lib/comms';
import './MicroLearning.css';
import moment from 'moment';

export default class MicroLearningPage extends React.Component {
  constructor (props) {
    super(props);
    const isNew = props.learning && props.learning.id === 'new';
    const learning = isNew ? {id: 'new', name: '', description: '', effort_level: 1, time_to_complete: 1, posted_date: null, active: 1, out_of: 0} : props.learning;
    this.state = {learning, editing: [isNew], showDeleteDialog: false, showMessage: null, isNew, activeTab: 'About'};
  }

  setEditing = async (index) => {
    let {editing, learning} = this.state;
    if (editing[index]) { // save to db
      if (!learning.name) return this.setState({showMessage: {message: 'Please include a valid title', title: 'Save material'}});
      if (!learning.description) return this.setState({showMessage: {message: 'Please include a valid description', title: 'Save material'}});
      const resp = await post('cms/learnings', {learning}, true);
      if (resp && resp.success) {
        editing[index] = false;
        if (resp.id) {
          editing[1] = true;
          learning.id = resp.id;
          this.setState({learning, isNew: false, activeTab: 'Questions'});
        }
      } else console.log(resp);
      if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error'}}), 500);
      editing[index] = false;
    } else editing[index] = true;
    this.setState({editing});
  }

  setAnswers = async ({content, outOf}) => {
    const learning = {...this.state.learning, content, out_of: outOf};
    console.log(learning);
    let {editing} = this.state;
    editing[1] = false;
    this.setState({learning, editing});
    const resp = await post('cms/learnings', {learning}, true);
    if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error'}}), 500);
  }

  doDelete = async () => {
    const resp = await dodelete('cms/learnings', {id: this.state.learning.id}, true);
    await this.setState({showDeleteDialog: false});
    if (resp && resp.success && this.props.onBack) this.props.onBack();
    else if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Delete Learning Material'}}), 500);
  }

  doPost = async () => {
    const learning = {...this.state.learning, posted_date: moment().format('YYYY-MM-DD')};
    this.setState({learning});
    const resp = await post('cms/learnings', {learning}, true);
    if (resp.message) setTimeout(() => this.setState({showMessage: {message: resp.message, title: 'Save error'}}), 500);
  }

  render () {
    let {learning, editing, activeTab} = this.state;
    let tabs = ['About'];
    // if (!isNew && !learning.posted_date) tabs.push('Questions');
    tabs.push('Questions');
    if (learning.posted_date) tabs.push('Results');
    return (
      <div className="maincontainer">
        <BackRow onBack={this.props.onBack} buttonTitle={activeTab === 'About' && !this.state.isNew ? 'DELETE MATERIAL' : null} onDelete={() => this.setState({showDeleteDialog: true})}/>
        <TabRow onChange={activeTab => this.setState({activeTab})} activeTab={this.state.activeTab} tabs={tabs}/>
        {activeTab === 'About' ? <About learning={learning} editing={editing[0]} onToggle={() => this.setEditing(0)} onEdit={what => this.setState({learning: {...learning, ...what}})} onPost={this.doPost}/> : null}
        {activeTab === 'Questions' ? <Questions learning={learning} onEdit={this.setAnswers} editing={editing[1] || null} allowEditAnswers={!learning.posted_date}/> : null}
        {activeTab === 'Results' ? <Results learning={learning}/> : null}
        {this.state.showDeleteDialog ? <DeleteDialog item='Material' onSuccess={this.doDelete} onClose={() => this.setState({showDeleteDialog: false})}/> : null}
        {this.state.showMessage ? <MessageDialog message={this.state.showMessage.message} title={this.state.showMessage.title} onClose={() => this.setState({showMessage: null})}/> : null}
      </div>
    );
  }
}
