import React, {
  useEffect,
  useState
} from 'react';

import {getData, deleteData, setData} from '../lib/data';
import {getUserFromToken} from '../lib/userprocs';

const UserContext = React.createContext();

const UserContextProvider = ({children}) => {
  const [token, setToken] = useState(getData('token', null));
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token) {
      setData('token', token, true);
      const userFromToken = getUserFromToken(token);
      setUser(userFromToken);
    } else {
      deleteData('token', null, true);
      setUser(null);
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        // values
        token,
        user,
        // functions
        setToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export {UserContextProvider, UserContextConsumer, UserContext};
