import React from "react";
import SearchRow from "components/SearchRow/SearchRow";
import { get } from "lib/comms";
import Table from "components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ContentPage from "./ContentPage";
import { Redirect } from "react-router-dom";

const columns = [
  {
    name: "Content Type",
    selector: "content_type",
    sortable: true,
    grow: 3,
    wrap: true,
  },
  {
    name: "",
    selector: "id",
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />,
  },
];

export default class ContentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], search: "", editing: null };
  }

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get("cms/appcontent", null, true);
    if (resp && resp.status === 401) this.setState({ logout: true });
    if (resp && resp.appcontent) this.setState({ data: resp.appcontent });
    else console.log(resp);
  };

  doneEditing = () => {
    this.setState({ editing: null });
    this.reload();
  };

  render() {
    if (this.state.logout)
      return (
        <Redirect
          to={{ pathname: "/logout", state: { from: this.props.location } }}
        />
      );
    let { data, search, editing } = this.state;
    if (editing)
      return <ContentPage item={editing} onBack={this.doneEditing} />;

    if (search)
      data = data.filter(
        (item) =>
          (item.content_type || "")
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
      );
    return (
      <div className="maincontainer">
        <h4>Onboarding Screens</h4>
        <SearchRow
          value={search}
          searchTitle="Search by content type"
          onSearch={(search) => this.setState({ search })}
        />
        <Table
          columns={columns}
          data={data}
          onRowClicked={(item) => this.setState({ editing: item })}
          tableID="acppcontent"
        />
      </div>
    );
  }
}
