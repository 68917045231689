import React from 'react';
import {get} from 'lib/comms';
import TextField, {Input} from '@material/react-text-field'; // https://material-components.github.io/material-components-web-catalog/#/component/text-field?type=outlined
import {createDashboardWidgetSettings} from '../../../../../lib/dashboard';
import SmallLoader from '../../../../../components/SmallLoader/SmallLoader';

const colors = {
  other: '#F0F0F0',
  high: '#4AB793',
  medium: '#FBB811',
  low: '#AF365F'
};

const allItems = [
  {name: 'avg. pulse score', id: 'pulse', defaultVals: {low: 60, high: 70, enabled: 1}},
  {name: 'points earned', id: 'points', defaultVals: {low: 1000, high: 2000, enabled: 1}},
  // {name: 'participating in challenges', id: 'challenges', defaultVals: {low: 60, high: 70, enabled: 1}},
  // {name: 'micro-learnings completed', id: 'learnings', defaultVals: {low: 60, high: 70, enabled: 1}},
  // {name: 'avg. micro-learning score', id: 'learningsscore', defaultVals: {low: 60, high: 70, enabled: 1}},
  {name: 'participating in events', id: 'events', defaultVals: {low: 60, high: 70, enabled: 1}},
  {name: 'high fives sent', id: 'highfives', defaultVals: {low: 60, high: 70, enabled: 1}},
  {name: 'new groups', id: 'newgroups', defaultVals: {low: 60, high: 70, enabled: 1}, neutral: 1},
  {name: 'active groups', id: 'activegroups', defaultVals: {low: 60, high: 70, enabled: 1}}
];

export default class extends React.Component {
  state = {data: [], selected: 'All', status: 'LOADING'};

  componentDidMount = async () => this.reload();

  reload = async () => {
    const {settings} = this.props;

    this.setState({
      status: 'LOADING'
    });

    const {data} = await get('cms/dashboard_data/stats', createDashboardWidgetSettings(settings), true);

    if (Array.isArray(data)) {
      this.setState({
        data,
        status: 'READY'
      });
    }
  }

  render () {
    const {data, selected, status} = this.state;
    const {width, thresholds = {}, showThresholds} = this.props;
    const boxWidth = width === 3 ? '10%' : width === 2 ? '14.2%' : '44%';

    if (status === 'LOADING') {
      return <div style={localStyle.outer}><SmallLoader /></div>;
    }

    let showData = data
      .filter(item => !thresholds[item.id] || thresholds[item.id].enabled !== 0)
      .map(item => {
        let {type} = item;
        const lookupItem = allItems.find(allItem => allItem.id === item.id) || {};
        const threshold = thresholds[item.id] || lookupItem.defaultVals;
        if (lookupItem.neutral || !threshold) type = 'other';
        else {
          if (item.value > threshold.high) type = 'high';
          else if (item.value > threshold.low) type = 'medium';
          else type = 'low';
        }
        return {...item, type, color: colors[type]};
      });

    const xlsData = showData.map(item => ([item.name, item.value]));
    if (this.props.onXLSData) this.props.onXLSData(xlsData);

    // filter based on tabbar selection
    if (selected !== 'All') showData = showData.filter(item => selected.toLowerCase() === item.type);

    if (showThresholds) return <Thresholds width={width} thresholds={thresholds} onSave={this.props.onSave} />;

    return (
      <div style={localStyle.outer}>
        <TabBar width={width} selected={selected} onSelect={selected => this.setState({selected})} />
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'flext-start'}}>
          {showData.map((item, index) => {
            const text = item.value + (item.isPercent ? '%' : '') + (item.outOf ? `/ ${item.outOf}` : '');
            return (
              <div style={{...localStyle.medalOuter, width: boxWidth}} key={index}>
                <div style={{...localStyle.square, backgroundColor: item.color}}>
                  <div style={{...localStyle.innerSquare, fontSize: text.length > 4 ? 22 : 32}}>{text}</div>
                </div>
                <div style={localStyle.textOuter}>
                  <p className="mb-0 grey" style={localStyle.nameText}>{item.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class Thresholds extends React.Component {
  constructor (props) {
    super(props);
    const {thresholds = {}} = props;

    const state = {};
    for (const item of allItems) state[item.id] = thresholds[item.id] || item.defaultVals; // eslint-disable-line no-unused-vars
    this.state = state;
    this.initialState = JSON.stringify(state);
  }

  rollbackState = () => {
    const initialState = JSON.parse(this.initialState);
    this.setState(initialState);
    this.props.onSave(initialState);
  }

  updateState = (id, key, val) => {
    let newState = {};
    newState[id] = this.state[id];
    newState[id][key] = parseInt(val || 0);
    this.setState(newState);
  }
  render () {
    const items = allItems.map(item => ({...item, vals: this.state[item.id]}));
    return (
      <div style={localStyle.outer}>
        <div style={{flex: 1}}>
          {items.map((item, index) => <ThresholdRow width={this.props.width} key={'thresholdrow_' + index} {...item} onChange={(index, val) => this.updateState(item.id, index, val)} />)}
        </div>
        <div style={localStyle.settingsFooter}>
          <button className="text-button" onClick={() => {
            this.rollbackState();
          }}>Cancel</button>
          <button className="blue-button" onClick={() => this.props.onSave(this.state)}>Save</button>
        </div>
      </div>
    );
  }
}

const ThresholdRow = (props) => {
  const {width, vals: {enabled = true, low, high}, neutral} = props;

  const outerExtra = width === 1 ? {flexDirection: 'column', alignItems: 'flex-start', margin: '16px 0', height: neutral ? 30 : 90} : {flexDirection: 'row', alignItems: 'center', margin: '6px 0', height: 60, border: '1px solid #ECEEF0', borderRadius: 4};
  const inputWidth = width === 1 ? 110 : 130;
  return (
    <div style={{display: 'flex', justifyContent: width === 1 ? 'flex-start' : 'space-between', padding: '0 10px', ...outerExtra}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <input type="checkbox" className="mr-2" id={'chb' + props.id} value='1' checked={enabled} onChange={e => props.onChange('enabled', enabled ? 0 : 1)} />
        <label className="form-check-label" htmlFor={'chb' + props.id}>{props.name}</label>
      </div>
      <div style={{display: neutral ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', marginTop: width === 1 ? 12 : 0}}>
        <TextField className="mr-3" label='Medium Target' outlined style={{width: inputWidth, backgroundColor: '#FFF', height: 50, borderBottom: '4px solid #FACF30'}}>
          <Input className="poppins" id={`low_${props.id}`} value={low} onChange={e => {
            if (typeof high === 'number' && e.target.value >= high) {
              return;
            }
            props.onChange('low', e.target.value);
          }} />
        </TextField>
        <TextField label='High Target' outlined style={{width: inputWidth, backgroundColor: '#FFF', height: 50, borderBottom: '4px solid #7DCCB1'}}>
          <Input className="poppins" id={`high_${props.id}`} value={high} onChange={e => {
            if (typeof low === 'number' && e.target.value <= low) {
              return;
            }
            props.onChange('high', e.target.value);
          }} />
        </TextField>
      </div>
    </div>
  );
};

const TabBar = props => {
  const tabs = ['All', 'High', 'Medium', 'Low', 'Other'];
  return (
    <div style={{height: 30, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start', maxWidth: 400}}>
      {tabs.map(tab => {
        if (props.selected === tab) return <div key={tab} style={localStyle.tabBarText}>{tab}</div>;
        return <button onClick={() => props.onSelect(tab)} className="text-button-no-height" key={tab} style={localStyle.tabBarButton}>{tab}</button>;
      })}
    </div>
  );
};

const localStyle = {
  outer: {padding: 16, overflowY: 'scroll'},
  medalOuter: {display: 'flex', flexDirection: 'column', marginTop: 12, marginRight: 10},
  square: {width: '100%', paddingTop: '100%', borderRadius: '4px', position: 'relative', overflowWrap: 'break-word'},
  innerSquare: {position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '32px', fontWeight: 700, textAlign: 'center'},
  textOuter: {display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center', marginRight: 10},
  nameText: {fontSize: 13, color: '#505A62'},
  tabBarText: {fontSize: 18, fontWeight: '500', borderBottom: '3px solid #000'},
  tabBarButton: {fontSize: 18, fontWeight: '500'},
  settingsFooter: {display: 'flex', alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, marginBottom: 0}
};
