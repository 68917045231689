import React from "react";
import SearchRow from "components/SearchRow/SearchRow";
import { get } from "lib/comms";
import Table from "components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import GroupPage from "./GroupPage";
import "./GroupsPage.css";
import AuthImage from "components/AuthImage/AuthImage";
import { Redirect } from "react-router-dom";
import placeholder from "assets/placeholder.png";

const columns = [
  {
    name: "",
    selector: "image_id",
    grow: 0,
    sortable: false,
    cell: (row) =>
      row.image_id ? (
        <AuthImage
          url={`content/image/${row.image_id}`}
          className="group-image"
          alt=""
        />
      ) : (
        <img src={placeholder} className="group-image" alt="" />
      ),
  },
  { name: "Topic Name", selector: "name", sortable: true, grow: 3, wrap: true },
  { name: "Category", selector: "category_name", sortable: true },
  {
    name: "No. of Members",
    selector: "member_count",
    center: true,
    sortable: true,
  },
  {
    name: "Org group",
    selector: "is_org_level",
    center: true,
    sortable: true,
    format: (row) => (row.is_org_level ? "Yes" : ""),
  },
  {
    name: "",
    selector: "id",
    sortable: false,
    grow: 0,
    right: true,
    cell: () => <FontAwesomeIcon color="#999" icon={faChevronRight} />,
  },
];
export default class GroupsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], search: "", editing: null };
  }

  componentDidMount = () => this.reload();

  doneEditing = () => {
    this.setState({ editing: null });
    this.reload();
  };

  reload = async () => {
    let resp = await get("cms/groups", null, true);
    if (resp && resp.status === 401) this.setState({ logout: true });
    if (resp && resp.groups) this.setState({ data: resp.groups });
    else console.log(resp);
  };

  render() {
    if (this.state.logout)
      return (
        <Redirect
          to={{ pathname: "/logout", state: { from: this.props.location } }}
        />
      );
    let { data, search, editing } = this.state;
    if (editing) return <GroupPage group={editing} onBack={this.doneEditing} />;
    if (search)
      data = data.filter(
        (item) =>
          `${item.name} ${item.category_name}`
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
      );
    return (
      <div className="maincontainer">
        <h4>Topic Management</h4>
        <SearchRow
          value={search}
          searchTitle="Search by group name or category"
          onSearch={(search) => this.setState({ search })}
        />
        <Table
          columns={columns}
          data={data}
          onRowClicked={(item) => this.setState({ editing: item })}
          tableID="groups"
          defaultSortField={columns[1].selector}
        />
      </div>
    );
  }
}
