import React, {useState} from 'react';
import {post, dodelete, put} from 'lib/comms';
import {EditText} from 'components/EditText/EditText';
import {Redirect} from 'react-router-dom';
import {faPlusCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DeleteDialog} from 'components/Dialog/Dialog';

export default class Tag extends React.Component {
  constructor (props) {
    super(props);
    this.state = {tag: props.tag, isAdd: false, showDeleteDialog: false};
  }

  handleAdd = async () => {
    if (this.state.tag.options.length >= 25) { return; }
    this.setState({isAdd: true});
  };

  handleOptionEdit = async (e, optionId) => {
    const {tag} = this.state;
    let editedOption = tag.options.find(o => o.id === optionId);
    editedOption.editedLabel ? delete editedOption.editedLabel : editedOption.editedLabel = editedOption.label;
    this.setState({tag});
  };

  handleOptionEditChange = async (e, optionId) => {
    const {tag} = this.state;
    let editedOption = tag.options.find(o => o.id === optionId);
    editedOption.editedLabel = e;
    this.setState({tag});
  };

  handleOptionEditSave = async (e, optionId) => {
    const {tag} = this.state;
    let editedOption = tag.options.find(o => o.id === optionId);
    const resp = await put(`cms/option/${optionId}`, {option: editedOption.editedLabel}, true);
    if (resp.success) {
      editedOption.label = editedOption.editedLabel;
      delete editedOption.editedLabel;
      this.setState({tag});
    } else {
      alert(resp);
      delete editedOption.editedLabel;
    }
  };

  handleOptionDelete = async (optionId) => {
    const resp = await dodelete(`cms/option/${optionId}`, [], true);
    if (resp.success) {
      const {tag} = this.state;
      tag.options = tag.options.filter(o => o.id !== optionId);
      this.setState({tag: tag});
    } else {
      alert(resp);
    }
  };

  handleOptionSave = async (e, newOption) => {
    const resp = await post(`cms/tag/${this.state.tag.id}/option`, {option: newOption}, true);
    if (resp.success) {
      const {tag} = this.state;
      tag.options.push(resp.option);
      this.setState({tag, isAdd: false});
    } else {
      alert(resp);
    }
  };

  handleOptionCancel = () => this.setState({isAdd: false});

  render () {
    if (this.state.logout) return <Redirect to={{pathname: '/logout', state: {from: this.props.location}}} />;
    let {tag} = this.state;
    return (
      <div key={tag.id} className={'tag-plate'}>
        <div className={'tag-name'}>
          <span className={'tag-title'}>{tag.title}</span>
          <span className={'tag-type'}>{tag.type === 1 ? `Multiple Choice Type, ${tag.options.length} tags ` : 'Binary Choice Type '}</span>
        </div>
        <div className={'tag-options'}>
          <TagOptions type={tag.type} options={tag.options} isAdd={this.state.isAdd} optionEdit={this.handleOptionEdit} optionEditChange={this.handleOptionEditChange} optionEditSave={this.handleOptionEditSave} save={this.handleOptionSave} delete={this.handleOptionDelete} cancel={this.handleOptionCancel} />
        </div>
        <div className={'tag-create-buttons'}>
          {tag.type === 1 ? <button onClick={this.handleAdd} className={'btn btn-primary tag-add-option-button'}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}} />ADD TAG</button> : null}
          <button onClick={() => this.setState({showDeleteTypeDialog: true})} className={'btn btn-danger tag-delete-button'}><FontAwesomeIcon icon={faTrashAlt} style={{marginRight: '10px'}} />DELETE TYPE</button>
        </div>
        {this.state.showDeleteTypeDialog ? <DeleteDialog item='Type' onSuccess={() => this.props.handleDeleteTag(tag.id)} onClose={() => this.setState({showDeleteTypeDialog: false})} /> : null}
      </div>
    );
  }
}

function TagOptions (props) {
  let options = props.options || [];
  const [newOption, setNewOption] = useState('');
  let optionsList = '';
  if (props.type === 1) optionsList = options.map(option => <Option isMultiple key={option.id} option={option} edit={props.optionEdit} editChange={props.optionEditChange} saveEdited={props.optionEditSave} delete={props.delete} />);
  else if (props.type === 2 && options[0]) {
    const option = options[0];
    optionsList = <Option isMultiple={false} option={option} edit={props.optionEdit} editChange={props.optionEditChange} saveEdited={props.optionEditSave} />;
  }

  return (
    <div>
      {optionsList}
      {props.isAdd
        ? <div className={'tag-option'}>
          <OptionLine />
          <EditText label="Tag Title" outerClassName='tag-outer-edit mb-2 mt-2' editing={'1'} onChange={e => setNewOption(e)} value={newOption} />
          <div className={'option-control-buttons'}>
            <button className={'button-edit-option'} onClick={(e) => props.save(e, newOption) && setNewOption('')}>Save</button>
            <span className={'vertical-divider'}>|</span>
            <button className={'button-delete-option'} onClick={(e) => props.cancel(e)}>Cancel</button>
          </div>
        </div> : ''}
    </div>
  );
}

function Option (props) {
  const [deleting, setDeleting] = useState(false);
  if (props.option.editedLabel) {
    return (
      <div className={'tag-option'}>
        {props.isMultiple ? <OptionLine /> : null}
        {/* <input type="text" value={props.option.editedLabel} onChange={e => props.editChange(e, props.option.id)}/> */}
        <EditText label="Label Text" editing={1} outerClassName='tag-outer-edit mb-2 mt-2' className={''} marginLeft={0} onChange={e => props.editChange(e, props.option.id)} value={props.option.editedLabel} />
        <div className={'option-control-buttons'}>
          <button className={'button-edit-option'} onClick={(e) => props.saveEdited(e, props.option.id)}>Save</button>
          <span className={'vertical-divider'}>|</span>
          <button className={'button-delete-option'} onClick={(e) => props.edit(e, props.option.id)}>Cancel</button>
        </div>
      </div>
    );
  }
  return (
    <div className={'tag-option'}>
      {props.isMultiple ? <OptionLine /> : null}
      <span className={'tag-item-title'}>{props.option.label}</span>
      <div className={'option-control-buttons'}>
        <button className={'button-edit-option'} onClick={(e) => props.edit(e, props.option.id)}>Edit</button>
        {props.delete ? <> <span className={'vertical-divider'}>|</span><button className={'button-delete-option'} onClick={() => setDeleting(true)}>Delete</button></> : null}
      </div>
      {deleting ? <DeleteDialog item='Tag' onSuccess={() => props.delete(props.option.id)} onClose={() => setDeleting(false)} /> : null}
    </div>
  );
}

// Option line draws the left lines for the tag options, made up of 2 divs, top one has left and bottom border, bottom one has left only (except :last-of-type which has no border)
const OptionLine = props => (
  <div className='tag-line-outer'>
    <div className='tag-line-inner1'></div>
    <div className='tag-line-inner2'></div>
  </div>
);
