import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import TokenPage from './pages/login/TokenPage';
import MainPage from './pages/main/MainPage';
import {deleteData} from './lib/data';
import {UserContextProvider, UserContextConsumer} from './contexts/UserContext';

export default class App extends React.Component {
  logout = (renderProps) => {
    deleteData('token', '', true);
    return <Redirect to={{pathname: '/', state: {from: this.props.location}}}/>;
  }

  render () {
    return (
      <UserContextProvider>
        <div className="outer">
          <div className="inner">
            <Router>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/logout" render={() => (
                  <UserContextConsumer>
                    {
                      ({setToken}) => {
                        setToken(null);
                        return <Redirect to={{pathname: '/', state: {from: this.props.location}}}/>;
                      }
                    }
                  </UserContextConsumer>
                )}/>
                <Route path="/token_auth" component={TokenPage}/>
                <Route path="/" component={MainPage} />
              </Switch>
            </Router>
          </div>
        </div>
      </UserContextProvider>
    );
  }
}
