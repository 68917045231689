import React, {useRef, useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {widgetList, widgetName, WidgetInner, widgetOptions} from './widgets/index';
import Settings, {getSettingsText} from './widgets/Settings';

export default class Widgets extends React.Component {
  render () {
    let widgets = this.props.widgets || '[]';
    if (typeof widgets === 'string') widgets = JSON.parse(widgets);

    const {editingLayout, onRearrange, setWidth, addNewWidget, onDeleteWidget, onSaveSettings, onSaveThresholds, departments, locations} = this.props;

    return (
      <div style={{flex: 1, flexDirection: 'row', display: 'flex', flexWrap: 'wrap', marginLeft: -6, marginRight: -6}}>
        {widgets.map((widget, index) => {
          if (editingLayout) return <DragWidget key={widget.id} {...widget} index={index} onRearrange={onRearrange} deleteWidget={onDeleteWidget} setWidth={setWidth} />;
          return <Widget onXLSData={data => this.props.onXLSData(index, data)} key={widget.id} {...widget} {...{index, onSaveSettings, onSaveThresholds, departments, locations}} />;
        })}
        {editingLayout ? <AddNewWidget onAdd={addNewWidget} /> : null}
      </div>
    );
  }
}

const Widget = React.forwardRef(({id, type, width, settings = {}, thresholds = {}, departments, locations, onSaveSettings, onSaveThresholds, onXLSData}, ref) => {
  const widgetRef = useRef(null);

  const [showSettings, setShowSettings] = useState(false);
  const [showThresholds, setShowThresholds] = useState(false);
  return (
    <div style={{...localStyle.widgetOuter, width: 33.3 * width + '%'}}>
      <div style={{...localStyle.widgetInner, minHeight: width === 3 ? 300 : 400, maxHeight: width === 3 ? null : 400, position: 'relative'}}>
        <div style={localStyle.widgetTitleBar}>
          <div style={localStyle.widgetTitle}>{widgetName(type, settings)}</div>
          <div style={{...localStyle.widgetSettings, display: (showThresholds ? 'none' : 'block')}}>
            {getSettingsText(settings, departments, locations, widgetOptions(type))}
            <button className="text-button" style={localStyle.widgetButton} onClick={() => setShowSettings(true)}> Settings</button>
            {type === 'stats' ? <button className="text-button" style={localStyle.widgetButton} onClick={() => setShowThresholds(true)}> Thresholds</button> : null}
          </div>
        </div>
        <WidgetInner
          ref={widgetRef}
          {...{id, type, width, thresholds, settings, showSettings, showThresholds, onXLSData}}
          onSave={(thresholds) => {
            if (thresholds) onSaveThresholds(id, thresholds);
            setShowThresholds(false);
            setTimeout(() => {
              if (widgetRef.current.reload) widgetRef.current.reload();
            }, 500);
          }}
        />
        {showSettings ? <Settings
          {...{type, width, settings, departments, locations, widgetType: type}}
          onCancel={(() => {
            const rollbackSettings = {...settings};
            return () => {
              const innerRollbackSettings = rollbackSettings;
              onSaveSettings(id, innerRollbackSettings);
              setShowSettings(false);
            };
          })()}
          onSave={(settings) => {
            onSaveSettings(id, settings);
            setShowSettings(false);
            setTimeout(() => {
              if (widgetRef.current.reload) widgetRef.current.reload();
            }, 500);
          }} /> : null}
      </div>

    </div>
  );
});

export const DragWidget = (props) => {
  const ref = useRef(null);

  const [deleting, setDeleting] = useState(false);

  const [, drop] = useDrop({
    accept: 'item',
    // drop: () => console.log('drop'),
    hover: (item, monitor) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) return;
      props.onRearrange(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
  const [{isDragging}, drag] = useDrag({
    item: {id: props.id, type: 'item', index: props.index},
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });

  drag(drop(ref));

  return (
    <div style={{...localStyle.widgetOuter, width: 33.3 * props.width + '%', opacity: isDragging ? 0.2 : 1}}>
      <div style={{...localStyle.widgetInner, height: 200}} ref={ref}>
        {deleting ? (
          <>
            <div className='card-title-outer editing'>
              <div className='card-title'>Delete Widget</div>
            </div>
            <div style={{backgroundColor: '#FFF', padding: 8, display: 'flex', flexDirection: 'column', flex: 1}}>
              Are you sure?
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', flex: 1, justifyContent: 'center'}}>
                <button className={'btn btn-primary mr-2'} style={{width: 120}} onClick={() => props.deleteWidget(props.id)}>Yes</button>
                <button className={'btn btn-secondary ml-2'} style={{width: 120}} onClick={() => setDeleting(false)}>No</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='card-title-outer editing' style={{display: 'flex', flexDirection: 'row', padding: 4, justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <button className={`btn btn-sm ${props.width === 1 ? 'btn-success' : 'btn-primary'} ml-1`} style={localStyle.button} onClick={() => props.setWidth(props.index, 1)}>S</button>
                <button className={`btn btn-sm ${props.width === 2 ? 'btn-success' : 'btn-primary'} ml-1`} style={localStyle.button} onClick={() => props.setWidth(props.index, 2)}>M</button>
                <button className={`btn btn-sm ${props.width === 3 ? 'btn-success' : 'btn-primary'} ml-1`} style={localStyle.button} onClick={() => props.setWidth(props.index, 3)}>L</button>
                <div className='card-title ml-2' style={{color: '#FFF'}}>{widgetName(props.type)}</div>
              </div>
              <button className={'btn btn-sm btn-danger mr-1'} style={localStyle.button} onClick={() => setDeleting(true)}><FontAwesomeIcon icon={faTrashAlt} /></button>
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', padding: 4, justifyContent: 'center', alignItems: 'center', color: '#999'}}>Drag to rearrange...</div>
          </>
        )}
      </div>
    </div>
  );
};

export const AddNewWidget = ({onAdd}) => {
  const [adding, setAdding] = useState(false);
  const addNew = (type) => {
    onAdd(type);
    setAdding(false);
  };
  return (
    <div style={{...localStyle.widgetOuter, width: '33.3%'}}>
      <div style={{...localStyle.widgetAddInner, height: 200}}>
        {adding ? (
          <>
            <div className='card-title-outer editing'>
              <div className='card-title'>Add Widget</div>
              <button onClick={() => setAdding(false)} className="card-edit text-button">Cancel</button>
            </div>
            <div style={{backgroundColor: '#FFF', overflowY: 'scroll'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {widgetList.map(item => <button key={item.type} onClick={() => addNew(item.type)} className="card-edit text-button">{item.name}</button>)}
              </div>
            </div>
          </>
        ) : (
          <button onClick={() => setAdding(true)} className="card-edit text-button" style={{flex: 1}}><FontAwesomeIcon icon={faPlusCircle} /> ADD WIDGET</button>
        )}
      </div>
    </div>
  );
};

const localStyle = {
  widgetOuter: {margin: 0, padding: 6},
  widgetInner: {border: '1px solid #DDD', borderRadius: 8, backgroundColor: '#FFF', display: 'flex', flexDirection: 'column'},
  widgetAddInner: {border: '1px dashed #008', borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden'},
  button: {height: 32},
  widgetTitleBar: {padding: 10, paddingBottom: 4, borderBottom: '1px solid #F4F4F4'},
  widgetTitle: {fontSize: 18, color: '#0D314A', fontWeight: '600', marginBottom: 4},
  widgetSettings: {fontSize: 15, color: '#5A656D'},
  widgetButton: {fontSize: 15, fontWeight: '500', color: '#017FB0', marginLeft: 8}
};
