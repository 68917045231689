import React from 'react';
import Top3 from './Top3';
import AppSessions from './AppSessions';
import Pulse from './Pulse';
import Stats from './Stats';
import Analytics from './Analytics';

export const widgetList = [
  {type: 'stats', name: 'Statistics'},
  {type: 'top', name: 'Top Users'},
  {type: 'sessions', name: 'App Sessions', tags: false, department: false},
  {type: 'pulse', name: 'Check in Pulse'},
  {type: 'group', name: 'Group Analytics'}
  // {type: 'knowledgebase', name: 'Knowledgebase'}
];

export const widgetName = (type, settings = {}) => {
  let {name = 'widget'} = widgetList.find(widget => widget.type === type) || {};
  if (type === 'top' && settings) name = `Top ${settings.count || 5} User${settings.count === 1 ? '' : 's'}`;
  return name;
};
export const widgetOptions = (type) => widgetList.find(widget => widget.type === type) || {};

export const WidgetInner = React.forwardRef((props, ref) => {
  if (props.type === 'top') return <Top3 {...props} ref={ref} />;
  if (props.type === 'sessions') return <AppSessions {...props} ref={ref} />;
  if (props.type === 'pulse') return <Pulse {...props} ref={ref} />;
  if (props.type === 'stats') return <Stats {...props} ref={ref} onSave={props.onSave} onCancel={props.onCancel} />;
  if (props.type === 'group') return <Analytics {...props} ref={ref} />;
  return (
    <div ref={ref} style={{display: 'flex', flex: 1, flexDirection: 'row', padding: 4, justifyContent: 'center', alignItems: 'center'}}>
      {props.id} {props.type} {props.width}
    </div>
  );
});
