import React from "react";
import BackRow from "components/BackRow/BackRow";
import Card from "components/Card/Card";
import { EditText, EditDropDown } from "components/EditText/EditText";
import { get, post, dodelete } from "lib/comms";
import { DeleteDialog, MessageDialog } from "components/Dialog/Dialog";
import { EditImage } from "components/EditImage/EditImage";
import SearchRow from "components/SearchRow/SearchRow";
import "./GroupPage.css";
import Table from "components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const categories = [ // prob want these from the db...
  { id: 1, name: 'Auto-Subscribed' },
  { id: 2, name: 'Regular' },
];

export default class GroupPage extends React.Component {
  columns = [
    {
      name: "Name",
      grow: 2,
      selector: "last_name",
      sortable: true,
      format: (row) =>
        `${row.first_name || ""} ${row.last_name || ""} ${
          row.deleted_at ? " (deleted)" : ""
        }`,
    },
    { name: "Email", grow: 2, selector: "email_address", sortable: true },
    {
      name: "Member Type",
      selector: "member_type",
      sortable: false,
      wrap: true,
      allowOverflow: true,
      cell: (row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle caret outline>
              {row.member_type === "admin" ? "Moderator" : "Standard"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  if (row.member_type === "admin") {
                    this.toggleMemberType(row);
                  }
                  this.setState({ openDropdownId: false });
                }}
              >
                Standard
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  if (row.member_type !== "admin") {
                    this.toggleMemberType(row);
                  }
                  this.setState({ openDropdownId: false });
                }}
              >
                Moderator
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          /*
                                                          <button
                                                            className="group-toggle-member-btn"
                                                            onClick={() => this.toggleMemberType(row)}
                                                          >
                                                            <Dot className="mt-1" color={row.member_type === 'admin' ? '#FBB811' : '#68C4D1'}
                                                            />
                                                            {row.member_type === 'admin' ? 'Moderator' : 'Standard'}
                                                          </button>
                                                          */
        );
      },
    },
    {
      name: "",
      selector: "id",
      sortable: false,
      right: true,
      cell: (row) => (
        <button
          className="group-remove-member-btn"
          onClick={() => this.deleteMember(row)}
        >
          <FontAwesomeIcon icon={faMinusCircle} /> Remove Member
        </button>
      ),
    },
  ];
  columns2 = [
    {
      name: "Name",
      grow: 2,
      selector: "last_name",
      sortable: true,
      format: (row) => `${row.first_name || ""} ${row.last_name || ""}`,
    },
    { name: "Email", grow: 2, selector: "email_address", sortable: true },
    {
      name: "",
      selector: "id",
      sortable: false,
      right: true,
      cell: (row) => (
        <button
          className="group-remove-member-btn group-add-member-btn"
          onClick={() => this.addMember(row)}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Add Member
        </button>
      ),
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      group: props.group,
      editing: [false],
      showDeleteDialog: false,
      showMessage: null,
      groupmembers: [],
      nongroupmembers: [],
      addingMembers: false,
    };
  }

  setEditing = async (index) => {
    let { editing, group } = this.state;
    if (editing[index]) {
      // save to db
      const resp = await post("cms/groups", { group }, true);
      if (resp && resp.success) editing[index] = false;
      else console.log(resp);
      if (resp.message)
        setTimeout(
          () =>
            this.setState({
              showMessage: { message: resp.message, title: "Save error" },
            }),
          500
        );
    } else editing[index] = true;
    this.setState({ editing });
  };

  componentDidMount = () => this.reload();

  reload = async () => {
    let resp = await get(`cms/groupmembers/${this.props.group.id}`, null, true);
    if (resp && resp.groupmembers)
      this.setState({ groupmembers: resp.groupmembers });
    else console.log(resp);

    // now get the list of users that are not members so we can add them
    resp = await get(
      `cms/groupmembers/${this.props.group.id}?nonmembers=1`,
      null,
      true
    );
    if (resp && resp.nongroupmembers)
      this.setState({ nongroupmembers: resp.nongroupmembers });
    else console.log(resp);
  };

  doDelete = async () => {
    const resp = await dodelete(
      "cms/groups",
      { id: this.state.group.id },
      true
    );
    await this.setState({ showDeleteDialog: false });
    if (resp && resp.success && this.props.onBack) this.props.onBack();
    else if (resp.message)
      setTimeout(
        () =>
          this.setState({
            showMessage: { message: resp.message, title: "Delete group" },
          }),
        500
      );
  };

  deleteMember = async (member) => {
    const resp = await dodelete(
      `cms/groupmembers/${this.props.group.id}`,
      { id: member.user_id },
      true
    );
    if (resp.success) {
      const groupmembers = this.state.groupmembers.filter(
        (groupmember) => groupmember.user_id !== member.user_id
      );
      const nongroupmembers = [...this.state.nongroupmembers, member];
      this.setState({ groupmembers, nongroupmembers });
    }
    if (resp.message)
      this.setState({
        showMessage: { message: resp.message, title: "Remove memeber" },
      });
  };

  addMember = async (member) => {
    const resp = await post(
      `cms/groupmembers/${this.props.group.id}`,
      { id: member.user_id },
      true
    );
    if (resp.success) {
      const nongroupmembers = this.state.nongroupmembers.filter(
        (groupmember) => groupmember.user_id !== member.user_id
      );
      const groupmembers = [...this.state.groupmembers, member];
      this.setState({ groupmembers, nongroupmembers });
    }
    if (resp.message)
      this.setState({
        showMessage: { message: resp.message, title: "Remove member" },
      });
  };

  toggleMemberType = async (member) => {
    const resp = await post(
      `/cms/groupmembers/${this.props.group.id}/toggle-user-permission/${member.user_id}`,
      {},
      true
    );
    if (resp.success) {
      const groupmembers = this.state.groupmembers.map((groupmember) => {
        if (groupmember.user_id === member.user_id)
          groupmember.member_type =
            groupmember.member_type === "admin" ? "standard" : "admin";
        return groupmember;
      });
      this.columns = [...this.columns]; // forces rerender!
      this.setState({ groupmembers });
    }
    if (resp.message)
      this.setState({
        showMessage: { message: resp.message, title: "Remove memeber" },
      });
  };

  render() {
    let {
      group,
      editing,
      groupmembers,
      nongroupmembers,
      search,
      addingMembers,
    } = this.state;
    let tableData = addingMembers ? nongroupmembers : groupmembers;
    if (search)
      tableData = tableData.filter(
        (item) =>
          `${item.first_name} ${item.last_name}`
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
      );
    return (
      <div className="maincontainer">
        <BackRow
          onBack={this.props.onBack}
          buttonTitle={group.is_org_level ? null : "DELETE GROUP"}
          onDelete={() => this.setState({ showDeleteDialog: true })}
        />

        <Card
          title="Group information"
          editing={editing[0] ? "1" : undefined}
          onToggle={() => this.setEditing(0)}
        >
          <div className="group-edit-outer">
            <div className="group-edit-image-outer mr-4">
              <EditImage
                editing={editing[0] ? "1" : undefined}
                imageid={group.image_id}
                onChange={(imageId) =>
                  this.setState({ group: { ...group, image_id: imageId } })
                }
              />
            </div>
            <div className="group-edit-text-outer">
              <p className="mt-2 mb-4" style={{ color: "#717171" }}>
                Members: {group.member_count}
              </p>
              <EditText
                label="Topic Name"
                editing={editing[0] ? "1" : undefined}
                onChange={(text) =>
                  this.setState({ group: { ...group, name: text } })
                }
                value={group.name}
              />
              <EditDropDown
                label="Category"
                options={categories}
                editing={editing[0]}
                onChange={(id) =>
                  this.setState({ group: { ...group, category_id: id } })
                }
                value={group.category_id}
              />
            </div>
          </div>
        </Card>

        <div className="mt-5">
          <SearchRow
            title="Group Members"
            value={search}
            searchTitle="Search by name or email address"
            onSearch={(search) => this.setState({ search })}
            className={addingMembers ? "success" : "primary"}
            buttonTitle={addingMembers ? "DONE" : "ADD MEMBERS"}
            onBtnClick={() =>
              this.setState({ addingMembers: !addingMembers, search: "" })
            }
          />
          <Table
            columns={addingMembers ? this.columns2 : this.columns}
            data={tableData}
            noSelect
            tableID="group"
          />
        </div>
        {this.state.showDeleteDialog ? (
          <DeleteDialog
            item="Group"
            onSuccess={this.doDelete}
            onClose={() => this.setState({ showDeleteDialog: false })}
          />
        ) : null}
        {this.state.showMessage ? (
          <MessageDialog
            message={this.state.showMessage.message}
            title={this.state.showMessage.title}
            onClose={() => this.setState({ showMessage: null })}
          />
        ) : null}
      </div>
    );
  }
}
