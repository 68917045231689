import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquare} from '@fortawesome/free-regular-svg-icons';
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons';

export default class Question extends React.Component {
  constructor (props) {
    super(props);
    this.state = {item: props.item};
  }

  editQuestion = (question) => this.updateItem({...this.state.item, question});

  editAnswer = (i, answer) => {
    const item = {...this.state.item};
    if (i < item.answers.length) item.answers[i].title = answer;
    else item.answers.push({title: answer, correct: false});
    this.updateItem(item);
  }

  editCorrect = (i, correct) => {
    const item = {...this.state.item};
    if (i < item.answers.length) item.answers[i].correct = correct;
    else item.answers.push({title: '', correct});
    this.updateItem(item);
  }

  // addAnswer = () => {
  //   const item = {...this.state.item};
  //   item.answers.push({title: '', correct: false});
  //   this.updateItem(item);
  // }

  updateItem = (item) => {
    this.setState({item});
    this.props.onEdit(item);
  }

  render () {
    const {item} = this.state;
    const {editing, index, allowEditAnswers} = this.props;
    const isQuestion = !!item.answers;
    let answers = [];
    if (isQuestion) {
      answers = [...item.answers];
      if (editing && allowEditAnswers) answers.push({title: '', correct: false});
    }
    return (
      <div className="learning-question mt-3">
        <div className="learning-border" style={{backgroundColor: isQuestion ? '#68C4D1' : '#FBB811'}}/>
        <div className="learning-title">
          <div className="mr-2">{index + 1}:</div>
          <div style={{flex: 1}}>{editing ? <textarea className="learning-question-title form-control" value={item.question} onChange={e => this.editQuestion(e.target.value)}/> : item.question}</div>
        </div>
        {isQuestion ? answers.map((answer, index) => <ItemAnswer editing={editing && allowEditAnswers} key={index} item={answer} index={index} onChange={this.editAnswer} onCorrect={this.editCorrect}/>) : null}
      </div>
    );
  }
}

const ItemAnswer = ({item, onChange, onCorrect, index, editing}) => (
  <div className="learning-answer-container mt-3">
    <input className="form-control" disabled={editing ? null : 'disabled'} value={item.title} onChange={e => onChange(index, e.target.value)} style={{backgroundColor: item.correct ? '#D2EDE4' : null}}/>
    <button className="learning-answer-checkbox-button ml-2" onClick={() => onCorrect(index, !item.correct)} disabled={editing ? null : 'disabed'}>
      <FontAwesomeIcon icon={item.correct ? faCheckSquare : faSquare} className="learning-answer-checkbox" style={{color: editing || item.correct ? '#4AB793' : '#888'}}/> Correct Answer
    </button>
  </div>
);
