import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'; // https://www.npmjs.com/package/xlsx
import {widgetName} from './widgets/index';

let xlsData = [];

export default (dashboards, selected) => {
  let dashboard = dashboards.find(dashboard => dashboard.name === selected);
  let {widgets} = dashboard;
  if (typeof widgets === 'string') widgets = JSON.parse(widgets);
  let sheetNames = [];
  let sheets = {};

  widgets.forEach((widget, i) => {
    if (xlsData[i]) {
      let sheetname = widgetName(widget.type, widget.settings);
      if (sheetNames.indexOf(sheetname) >= 0) sheetname += ` (${parseInt(i) + 1})`;
      sheetNames.push(sheetname);
      sheets[sheetname] = XLSX.utils.aoa_to_sheet(xlsData[i]);
    }
  });

  if (sheetNames.length) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const wb = {Sheets: sheets, SheetNames: sheetNames};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, `${selected}.xlsx`);
  }
};

export const saveXLSData = (index, data) => xlsData[index] = data;
export const resetXLSData = (index, data) => xlsData = [];
