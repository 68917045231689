import React from 'react';
import {get} from 'lib/comms';
import {createDashboardWidgetSettings} from '../../../../../lib/dashboard';
import SmallLoader from '../../../../../components/SmallLoader/SmallLoader';

export default class extends React.Component {
  state = {data: [], postPage: 0, pollPage: 0, status: 'LOADING'};

  componentDidMount = async () => this.reload();

  reload = async () => {
    const {settings} = this.props;

    this.setState({
      status: 'LOADING'
    });

    const {data} = await get('cms/dashboard_data/analytics', createDashboardWidgetSettings(settings), true);

    if (data && data.polls) {
      this.makeXLSPage(data);
      this.setState({
        data,
        status: 'READY'
      });
    }
  }

  makeXLSPage = (data) => {
    let out = [['Polls'], ['Date', 'Question', 'Group', 'Answer 1', 'Answer 2', 'Answer 3', 'Answer 4', 'Count 1', 'Count 2', 'Count 3', 'Count 4']];
    data.polls.forEach(poll => {
      let recArray = [poll.created_at, poll.question, poll.groupname, '', '', '', '', '', '', '', ''];
      (poll.answers || []).forEach((answer, index) => {
        recArray[index + 2] = answer.option_text;
        recArray[index + 6] = answer.score;
      });
      out.push(recArray);
    });

    if (data.posts) {
      out = [...out, [], ['Posts'], ['Date', 'Type', 'Post', 'Group', 'Likes', 'Comments']];
      data.posts.forEach(post => out.push([post.created_at, post.type, post.notice, post.groupname, post.likes, post.comments]));
    }
    if (this.props.onXLSData) this.props.onXLSData(out);
  }

  render () {
    const {data, status} = this.state;
    const {width} = this.props;

    if (status === 'LOADING') {
      return <div style={localStyle.outer}><SmallLoader /></div>;
    }

    const {polls = [], posts = [], pollEngagementAverage = 0, postEngagementAverage = 0} = data;
    // const showPolls = polls.slice(pollPage * pollsPerPage, (pollPage + 1) * pollsPerPage);
    // const showPosts = posts.slice(postPage * postsPerPage, (postPage + 1) * postsPerPage);
    return (
      <div style={localStyle.outer}>
        <div style={{display: 'flex', flexDirection: width === 1 ? 'column' : 'row', alignItems: 'flex-start'}}>
          <div style={{flex: 1}}>
            <div style={localStyle.title}>Top Polls</div>
            {
              polls.length > 0
                ? (
                  <>
                    <div style={localStyle.subtitle}>Avg. response rate <span style={localStyle.percent}>{(pollEngagementAverage || 0).toFixed(1)}%</span></div>
                    <div style={localStyle.innerContainer}>
                      {polls.map((poll, index) => <Poll key={`poll_${index}`} width={width} poll={poll}/>)}
                    </div>
                  </>
                )
                : (
                  <>
                    <div style={localStyle.subtitle}>No polls match your filter.</div><br />
                  </>
                )
            }
            {/* <Pagination pageSize={pollsPerPage} totalSize={polls.length} page={pollPage} onChange={diff => this.setState({pollPage: pollPage + diff})}/> */}
          </div>

          <div style={{flex: 1}}>
            <div style={localStyle.title}>Top Posts</div>
            {
              posts.length > 0
                ? (
                  <>
                    <div style={localStyle.subtitle}>Avg. engagement rate <span style={localStyle.percent}>{(postEngagementAverage || 0).toFixed(1)}%</span></div>
                    <div style={localStyle.innerContainer}>
                      {posts.map((post, index) => <Post key={`post_${index}`} width={width} post={post}/>)}
                    </div>
                  </>
                )
                : (
                  <div style={localStyle.subtitle}>No posts match your filter.</div>
                )
            }

            {/* <Pagination pageSize={postsPerPage} totalSize={posts.length} page={postPage} onChange={diff => this.setState({postPage: postPage + diff})}/> */}
          </div>
        </div>
      </div>
    );
  }
}

const Poll = ({width, poll}) => {
  const totalVotes = poll.answers.reduce((total, {score}) => total + score, 0);
  const biggestAnswer = poll.answers.reduce((total, {score}) => score > total ? score : total, 0);
  return (
    <div style={{width: width === 3 ? '50%' : '100%'}}>
      <div style={localStyle.inner}>
        <div style={localStyle.innerTitle}><span style={{color: '#017FB0'}}>Poll</span> - {poll.groupname}</div>
        <div style={localStyle.innerText}>{poll.question}</div>
        {poll.answers.map(({score, option_text: answer}, index) => (
          <div style={localStyle.answerOuter} key={'answer' + index}>
            <div style={{height: 28, width: '98%', position: 'relative'}}>
              <div className='gradient' style={{width: (95 * score / totalVotes) + '%', height: 28, borderRadius: 14, padding: '2px 6px', position: 'absolute'}}></div>
              <div style={{position: 'absolute', top: 2, left: 4, height: 28, overflow: 'hidden', fontWeight: score >= biggestAnswer ? '500' : '400'}}>{answer}</div>
            </div>
            <div style={{fontWeight: '500'}}>{score}</div>
          </div>
        ))}
        <div style={localStyle.innerPollFooter}>{`${totalVotes} vote${totalVotes === 1 ? '' : 's'} total`}</div>
      </div>
    </div>
  );
};

const Post = ({width, post}) => (
  <div style={{width: width === 3 ? '50%' : '100%'}}>
    <div style={{...localStyle.inner}}>
      <div style={localStyle.innerTitle}><span style={{color: '#017FB0'}}>{post.type || 'Notice'}</span> - {post.groupname}</div>
      <div style={localStyle.innerText}>{post.notice}</div>
      <div style={localStyle.innerPostFooter}>
        <div style={localStyle.innerPostFooterInner}>
          <div style={localStyle.innerPostFooterCounter}>{post.likes}</div>
          <div>like{post.likes === 1 ? '' : 's'}</div>
        </div>
        <div style={localStyle.innerPostFooterInner}>
          <div style={localStyle.innerPostFooterCounter}>{post.comments}</div>
          <div>comment{post.comments === 1 ? '' : 's'}</div>
        </div>
      </div>
    </div>
  </div>
);

const localStyle = {
  outer: {padding: 16, overflowY: 'scroll'},
  title: {fontSize: '18px', fontWeight: '500', letterSpacing: '-0.25px', color: '#505A62'},
  subtitle: {fontSize: '15px', fontWeight: '400', letterSpacing: '-0.15px', color: '#505A62'},
  percent: {fontSize: '22px', fontWeight: '600', letterSpacing: '-0.16px', color: '#0F2B41'},
  innerContainer: {display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginTop: 10},
  inner: {flex: 1, borderRadius: 4, border: '1px solid #C6C6C6', marginBottom: 8, marginRight: 8, padding: 10},
  innerTitle: {fontSize: '15px', fontWeight: '500', letterSpacing: '-0.15px', color: '#5A656D'},
  innerText: {fontSize: '15px', fontWeight: '400', letterSpacing: '-0.15px', color: '#505A62', maxHeight: 64, overflow: 'hidden', marginTop: 8},
  answerOuter: {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 4},
  innerPollFooter: {fontSize: '15px', fontWeight: '500', letterSpacing: '-0.15px', color: '#505A62', marginTop: 10},
  innerPostFooter: {display: 'flex', flexDirection: 'row', marginTop: 12},
  innerPostFooterInner: {marginRight: 10, color: '#505A62', fontSize: 14, letterSpacing: '-0.14px'},
  innerPostFooterCounter: {color: '#0F2B41', fontSize: 22, fontWeight: '600', letterSpacing: '-0.26px'},

  paginateOuter: {display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10},

  medalOuter: {display: 'flex', flexDirection: 'row', height: '66px'},
  medal: {minWidth: '50px', width: '50px', height: '50px', borderRadius: '4px', marginRight: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', fontWeight: 600},
  textOuter: {display: 'flex', flexDirection: 'column'},
  nameText: {fontSize: '17px', letterSpacing: '-0.2px', textOverflow: 'ellipsis', height: '22px', overflow: 'hidden', wordWrap: 'break-word'},
  emailText: {fontSize: '15px', letterSpacing: '-0.15px'}
};
