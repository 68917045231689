import React, {useCallback, useState, useEffect} from 'react';
import moment from 'moment';
import Card from '../../../../components/Card/Card';
import {get, post} from '../../../../lib/comms';
import SmallLoader from '../../../../components/SmallLoader/SmallLoader';
import {SettingsRow} from './SettingsRow';

const SettingsPage = () => {
  const [status, setStatus] = useState('LOADING'); // LOADING, READY, ERROR
  const [supportExpiry, setSupportExpiry] = useState(null);
  const [supportEnabled, setSupportEnabled] = useState(false);

  const [settings, setSettings] = useState(null);

  const [updatingSettings, setUpdatingSettings] = useState([]);

  useEffect(() => {
    const go = async () => {
      setStatus('LOADING');
      const supportActiveRequest = await get('cms/support', {}, true);

      if (supportActiveRequest && supportActiveRequest.success) {
        setSupportExpiry(supportActiveRequest.support_window_expires_at);
        setSupportEnabled(supportActiveRequest.support_window_expires_at && new Date(supportActiveRequest.support_window_expires_at) > new Date());
        setStatus('READY');
      } else {
        setStatus('ERROR');
      }
    };

    go();
  }, []);

  useEffect(() => {
    const go = async () => {
      const settingsRequest = await get('cms/settings', {}, true);

      if (settingsRequest && settingsRequest.success) {
        setSettings(settingsRequest.settings);
      } else {
        setStatus('ERROR');
      }
    };

    go();
  }, []);

  const requestSupportEnabled = useCallback((enabled) => () => {
    const go = async () => {
      setStatus('LOADING');
      const supportEnabledRequest = await post('cms/support', {enabled}, true);

      if (supportEnabledRequest && supportEnabledRequest.success) {
        setSupportExpiry(supportEnabledRequest.support_window_expires_at);
        setSupportEnabled(supportEnabledRequest.support_window_expires_at && new Date(supportEnabledRequest.support_window_expires_at) > new Date());
        setStatus('READY');
      } else {
        setStatus('ERROR');
      }
    };

    go();
  }, []);

  const updateSetting = useCallback((type, value) => {
    const go = async () => {
      setUpdatingSettings([
        ...updatingSettings,
        type
      ]);

      // make the call
      const settingRequest = await post(`cms/settings/${type}`, {value}, true);

      if (settingRequest && settingRequest.success) {
        const newSettings = {...settings};

        newSettings[type] = value;

        setSettings(newSettings);
      }

      setUpdatingSettings([]);
    };

    go();
  }, []);

  return (
    <div className="maincontainer">
      <h4>Settings</h4>
      <Card title="Organisation Settings">
        {
          settings === null
            ? (
              <SmallLoader color="#0F2B41" />
            )
            : (
              <SettingsRow
                title="Show Email Addresses In App"
                subtitle="Turning this off will ensure user emails never appear in the App."
                options={['Show Emails', 'Hide Emails']}
                values={[true, false]}
                value={settings.SHOW_EMAILS}
                onChange={val => {
                  updateSetting('SHOW_EMAILS', val);
                }}
                updating={updatingSettings.indexOf('SHOW_EMAILS') !== -1}
              />
            )
        }
      </Card>
      <Card title="Remote Support">
        {
          status === 'LOADING' &&
          (
            <SmallLoader color="#0F2B41" />
          )
        }
        {
          status === 'READY' && (
            <div>
              <div className="mb-2">Activating remote support will allow the vidatec team to log into your organisation and assist you with administration.</div>
              {
                supportEnabled
                  ? (
                    <div>
                      <button className="btn btn-primary" onClick={requestSupportEnabled(false)}>DEACTIVATE REMOTE SUPPORT</button>

                      <div className="mt-2">Remote support expires <b>{moment(supportExpiry).fromNow()}</b> ({moment(supportExpiry).format('dddd, MMMM Do YYYY, h:mm:ssa')})</div>
                    </div>
                  )
                  : (
                    <div>
                      <button className="btn btn-primary" onClick={requestSupportEnabled(true)}>ACTIVATE REMOTE SUPPORT</button>
                    </div>
                  )
              }
            </div>
          )
        }
        {
          status === 'ERROR' && (
            <div>Error getting remote support settings.</div>
          )
        }
      </Card>
    </div>
  );
};

export default SettingsPage;
